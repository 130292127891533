import ProductSelectorV2 from '@momentum/components/form-inputs/ProductSelector'
import PackagePlusIcon from '@momentum/components/icons/package-plus'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Product, requestExchangeRate } from '@momentum/routes/proposals-create/context/queries'
import ProductVariationsInput from '@momentum/routes/proposals-create/product-creators/ProductVariationsInput'
import { useRecommendation } from '@momentum/routes/proposals-create/product-creators/useRecommendation'
import { ProposalCreateForm, ProposalCreatorPricingInput } from '@momentum/routes/proposals-create/types'
import { creatorProductsFormValidationSchema } from '@momentum/routes/proposals-create/validations'
import { CreatorPricing } from '@momentum/routes/queries'
import { CREATOR_TYPE_PRICING_CONFIG } from '@momentum/utils/brandPricing'
import { CampaignGoalInformation } from '@momentum/utils/campaignGoalUtils'
import { isHoliday, isWeekend, minusBusinessDays } from '@momentum/utils/dateUtils'
import { CampaignGoalTypeToIcon } from '@momentum/utils/goalIcons'
import {
  generateContentRequirements,
  getDefaultCreatorPricing,
  getMinLaunchDate,
  RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES
} from '@momentum/utils/proposalUtils'
import { getCreatorPricingStore } from '@momentum/utils/storeUtils'
import {
  AddCircleRounded,
  ArrowForwardOutlined,
  Cancel,
  CancelRounded,
  CheckCircleOutlined,
  Close
} from '@mui/icons-material'
import { Alert, Button, Chip, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { Box, Stack } from '@mui/system'
import {
  DEFAULT_MANAGED_SERVICE_FEE,
  DEFAULT_PRICING,
  getAutoBuysDistribution,
  getBrandStorePricing,
  getSocialCreatorDistribution,
  MANAGED_SERVICE_CREATOR_TYPES,
  STORE_TO_CURRENCY,
  StorePricing,
  toWeeklyPartition
} from '@productwindtom/shared-momentum'
import { CreatorType, PricingCreatorType, ProposalGoal, Region } from '@productwindtom/shared-momentum-zeus-types'
import { toLocaleCurrency } from '@productwindtom/shared-node'
import { DateInput, NumberInput, SubmitButton } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { keyBy, max, sumBy, get } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useController, useFormContext, UseFormSetValue, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question } from '../../../components/proposal-common/Question'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import PlaceholderProductAlert from '../modules/placeholder-product-alert'
import { ExtraFeesForm } from './ExtraFeesForm'

const MAX_GENERATE_RECOMMENDATION_PASSES = 3

export const ProductCreators = () => {
  const selfServiceFlag = useFlag('ProposalSelfServiceSetup')
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')
  const isProposalVariationsEnabled = useFlag('ProposalVariations')

  const { isAdminView, agencies } = useUserSessionContext()
  const { company, brand, loadingProducts, setIsAddProductActiveOrCallback } = useBrandContext()
  const { selectableProducts, selectedProduct, setSelectedProduct, recommendationType, proposal } =
    useCreateProposalContext()
  const { setValue, getValues, watch, trigger } = useFormContext<ProposalCreateForm>()
  const [generateRecommendationPass, setGenerateRecomendationPass] = useState(0)

  const selectedProductId = watch('productId')
  const selectedGoal = watch('goal')
  const estimatedUnitsSoldPerMonth = watch('estimatedUnitsSoldPerMonth')
  const customFeedbackResponses = watch('customFeedbackResponses')
  const eventDate = watch('eventDate')
  const recommendedLaunchDate = watch('recommendedLaunchDate')
  const minLaunchDate = getMinLaunchDate(brand.region)

  const creatorPricing = watch('creatorPricing')
  const managedServiceFeeInputFlag = useFlag('ManagedServiceFeeInput')
  const managedServiceFee = watch('managedServiceFee')
  const managedServiceFeeAmount = managedServiceFee ? toLocaleCurrency(managedServiceFee) : null

  const productPricingStore = selectedProduct?.store ? getCreatorPricingStore(selectedProduct.store) : undefined
  const pricing = getBrandStorePricing(
    brand.region,
    brand.pricing,
    company.pricing,
    (agencies.find(a => a.id === company.agencyId) ?? company.agency)?.clientPricing
  ).find(sp => sp.store === productPricingStore)

  const {
    warnings,
    isDifferent,
    creatorCounts: recommendationCreatorCounts,
    durationWeeks,
    isSelfServiceDisabled,
    isSelfServiceSupported,
    saveRecommendation,
    savedRecommendation,
    clearSavedRecommendation
  } = useRecommendation()

  const storeCurrency = selectedProduct?.store ? STORE_TO_CURRENCY[selectedProduct?.store] : undefined

  useEffect(() => {
    if (selectedProductId) {
      const foundProduct = selectableProducts?.find(p => p.id === selectedProductId)
      if (foundProduct) {
        setSelectedProduct(foundProduct)
      }
    } else if (selectedProduct && !selectedProductId) {
      // This is to handle when setting the selected product from add manual product.
      setSelectedProduct(undefined)
    }
  }, [selectedProduct, selectedProductId, selectableProducts, setSelectedProduct])

  // Refresh exchange rate every time product currency changes
  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.id === proposal?.productId) {
        setValue('exchangeRate', proposal.exchangeRate || 1, {
          shouldValidate: true
        })
      } else {
        requestExchangeRate(STORE_TO_CURRENCY[selectedProduct.store]).then(exchangeRate => {
          setValue('exchangeRate', exchangeRate, {
            shouldValidate: true
          })
        })
      }
    } else {
      setValue('exchangeRate', undefined, {
        shouldValidate: true
      })
    }
  }, [storeCurrency])

  useEffect(() => {
    clearSavedRecommendation()
    if (selectedGoal !== ProposalGoal.EVENT) {
      setValue('eventDate', undefined)
      setValue('recommendedLaunchDate', undefined)
    }
  }, [selectedGoal])

  useEffect(() => {
    handleRecommendedDates({
      goal: selectedGoal,
      creatorPricingWithSchedule: getValues('creatorPricing'),
      brandRegion: brand.region,
      eventDate,
      setValue
    })
  }, [eventDate])

  useEffect(() => {
    const existingValues = getValues()
    if (selectedProduct) {
      if (existingValues.productPrice !== selectedProduct.priceCents) {
        setValue('productPrice', selectedProduct.priceCents)
      }
      if (existingValues.productRating !== selectedProduct.ratingSummary?.rating) {
        setValue('productRating', selectedProduct.ratingSummary?.rating)
      }
      if (existingValues.productRatingCount !== selectedProduct.ratingSummary?.numRatings) {
        setValue('productRatingCount', selectedProduct.ratingSummary?.numRatings)
      }
    }
  }, [selectedProduct, selectedGoal, recommendationType, pricing?.creatorPricing])

  useEffect(() => {
    const hasManagedServiceCreators = creatorPricing.some(
      c => MANAGED_SERVICE_CREATOR_TYPES.includes(c.type) && c.numCreators > 0
    )
    if (hasManagedServiceCreators) {
      setValue('managedServiceFeeEnabled', true, {
        shouldValidate: true
      })
      setValue(
        'managedServiceFee',
        managedServiceFeeInputFlag && managedServiceFee != null ? managedServiceFee : DEFAULT_MANAGED_SERVICE_FEE
      )
    }
    const brandAdvocates = creatorPricing.find(cp => cp.type === PricingCreatorType.ADVOCATE)
    setValue(
      'customFeedbackResponses',
      brandAdvocates?.numCreators ? Math.floor(brandAdvocates?.numCreators / 2) : undefined
    )
  }, [creatorPricing])

  const onAddProduct = () => {
    setIsAddProductActiveOrCallback({
      selectedProductId: selectedProduct?.id,
      productPriceOverride: proposal?.productPriceOverride,
      callback: async (product: Product) => {
        if (product) {
          setValue('productId', product.id)
          onSelectProduct(product)
        }
      }
    })
  }

  useEffect(() => {
    // recommendations for "Boost star rating" use durationWeeks to calculate, which in turn is affected by the recommended creator counts
    // se we need to regenerate the recommendation if the durationWeeks changed after the recommendation was generated
    if (
      selectedGoal === ProposalGoal.BOOST_RATING &&
      durationWeeks &&
      savedRecommendation &&
      savedRecommendation.durationWeeks !== durationWeeks &&
      generateRecommendationPass > 0 && // first pass is the initial generation
      generateRecommendationPass < MAX_GENERATE_RECOMMENDATION_PASSES
    ) {
      setGenerateRecomendationPass(generateRecommendationPass + 1)
      onGenerateRecommendation()
    } else {
      setGenerateRecomendationPass(0)
    }
  }, [selectedGoal, durationWeeks, savedRecommendation?.durationWeeks, generateRecommendationPass])

  const onGenerateRecommendation = () => {
    const vals = getValues()

    if (selectedProduct && recommendationCreatorCounts) {
      if (!generateRecommendationPass) {
        setGenerateRecomendationPass(1)
      }

      const pricingWithCreators = vals.creatorPricing.map(cp => ({
        ...cp,
        numCreators: recommendationCreatorCounts[cp.type] || 0
      }))
      const autoBuyDistribution = keyBy(getAutoBuysDistribution({ creatorPricing: pricingWithCreators }), 'type')
      const creatorPricingWithSchedule = pricingWithCreators.map(cp => ({
        ...cp,
        schedule: autoBuyDistribution[cp.type]?.dailySchedule
      }))
      setValue('creatorPricing', creatorPricingWithSchedule)

      handleRecommendedDates({
        goal: selectedGoal,
        creatorPricingWithSchedule,
        brandRegion: brand.region,
        eventDate,
        setValue
      })

      saveRecommendation?.()
      trigger('creatorPricing')
    }
  }

  const onSelectProduct = (product?: Product) => {
    const productPricingStore = product?.store ? getCreatorPricingStore(product.store) : undefined
    const brandPricing = getBrandStorePricing(
      brand?.region ?? Region.US,
      brand?.pricing,
      company?.pricing,
      (agencies.find(a => a.id === company?.agencyId) ?? company?.agency)?.clientPricing
    ).find(p => p.store === productPricingStore)

    const isDailyScheduling = getValues('isDailyScheduling')
    const creatorPricing = getValues('creatorPricing')

    const pricing = getDefaultCreatorPricing(
      brandPricing?.creatorPricing ?? [],
      (creatorPricing || []).map(p => ({ ...p, numCreators: 0 })),
      undefined,
      !!company?.agencyId,
      isDailyScheduling
    )

    setValue('creatorPricing', pricing)
    setValue('contentRequirements', generateContentRequirements(pricing))
  }

  const getDisabledGenerateButtonMessage = () => {
    if (!estimatedUnitsSoldPerMonth) {
      return 'Please enter how many units you expect to sell during the first 30 days of the campaign'
    }
    if (!selectedProduct) {
      return 'Please select a product'
    }
    if (!RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal)) {
      return 'Recommendations are not available for this campaign type'
    }
  }
  const disabledButtonMessage = getDisabledGenerateButtonMessage()
  const isEstimatedUnitSoldPerMonthError = !estimatedUnitsSoldPerMonth
  const isCustomFeedbackResponsesError = !customFeedbackResponses

  return (
    <Stack spacing={4} pb={2}>
      <Question
        primaryText={'Add product'}
        subtext={'Momentum helps products selling on Amazon, Walmart, Target and other retailers.'}
      >
        {!selectedProduct && (
          <Box data-cy={'productSelector'}>
            <ProductSelectorV2
              width={450}
              products={(selectableProducts || []).filter(p => p.priceCents)}
              onAddProductClick={onAddProduct}
              loadingProducts={loadingProducts}
              onSelectProduct={onSelectProduct}
            />
          </Box>
        )}
        {selectedProduct && <SelectedProduct product={selectedProduct} onRemove={() => setValue('productId', '')} />}
      </Question>
      {isProposalVariationsEnabled &&
        (selectableProducts.find(p => selectedProduct?.parentSkuId === p.id)?.childrenIds?.length ||
          selectedProduct?.childrenIds?.length ||
          0) > 1 && (
          <Question primaryText={'Select eligible product variations'}>
            <ProductVariationsInput />
          </Question>
        )}
      <PlaceholderProductAlert isAddProductEnabled />
      <Question
        primaryText={'Select campaign goal'}
        subtext={
          'Your campaign goal is the business goal you hope to achieve by running your campaign. Hover over each one for more information. Learn more.'
        }
      >
        <Box>
          <CampaignGoalSelector isAgency={!!company.agencyId} />
        </Box>
      </Question>

      {selectedGoal === ProposalGoal.EVENT && (
        <Question
          primaryText={'What is the start date of your holiday or promo?'}
          subtext={'Select the first date of your holiday or promo period.'}
        >
          <Box>
            <DateInput
              name="eventDate"
              minDate={DateTime.now().plus({ days: 1 })}
              slotProps={{ textField: { inputProps: { ['data-cy']: 'eventInput' } } }}
            />
          </Box>
        </Question>
      )}
      {!!selectedProduct && (
        <Question
          primaryText={
            'Without ProductWind, how many units would your product typically sell in the first 30 days of this campaign?'
          }
          subtext={
            'Do NOT include expected sales from this ProductWind campaign. ProductWind uses this to estimate when you will receive reviews & ratings.'
          }
        >
          <NumberInput
            name={'estimatedUnitsSoldPerMonth'}
            data-cy={'estimatedUnitsSoldPerMonth'}
            placeholder={'Units sold'}
            returnAsNumber
            hideError
            hideZero
            error={isEstimatedUnitSoldPerMonthError}
            allowNegative={false}
          />
        </Question>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.MARKET_RESEARCH && (
        <Question
          primaryText={'What is your desired number of responses?'}
          subtext={
            'Ask your brand advocates additional customized questions to get early product feedback from consumers.'
          }
        >
          <NumberInput
            name={'customFeedbackResponses'}
            data-cy={'customFeedbackResponses'}
            placeholder={'Number of responses'}
            returnAsNumber
            hideError
            hideZero
            error={isCustomFeedbackResponsesError}
            allowNegative={false}
          />
        </Question>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.BOOST_RATING && (
        <Question
          primaryText={'What is the product’s desired star rating?'}
          subtext={`The product currently has a ${selectedProduct.ratingSummary?.rating ?? '?'} star rating with ${selectedProduct.ratingSummary?.numRatings?.toLocaleString() ?? '?'} reviews and ratings.`}
        >
          <RatingGoalQuestion />
        </Question>
      )}

      {!!selectedProduct && selectedGoal === ProposalGoal.EVENT && (
        <Question
          primaryText={'ProductWind’s recommended start date:'}
          subtext={
            'This is the recommended campaign start date to produce the best SEO results ahead of your holiday or promo.'
          }
        >
          <Box>
            <DateInput
              name="recommendedLaunchDate"
              disabled
              readOnly
              slotProps={{
                field: {
                  InputProps: {
                    inputProps: { sx: { '&.Mui-disabled': { '-webkit-text-fill-color': 'black' } } },
                    sx: {
                      background: (theme: any) => theme.palette.grey.A200
                    }
                  }
                }
              }}
            />
          </Box>
        </Question>
      )}

      {!!selectedProduct && (
        <Stack spacing={2}>
          <Question
            subtext={'You can add creators or generate a recommendation based on your monthly sales'}
            primaryText={'Add creators'}
          >
            {proposalRecommendationFlag && isSelfServiceSupported && (
              <Stack spacing={2} mb={2}>
                <Tooltip disableHoverListener={!disabledButtonMessage} title={disabledButtonMessage}>
                  <Grid item xs={'auto'}>
                    <Button
                      variant={'outlined'}
                      size={'small'}
                      onClick={onGenerateRecommendation}
                      disabled={
                        (!isDifferent && !!savedRecommendation) ||
                        !RECOMMENDATION_SUPPORTED_PROPOSAL_TYPES.includes(selectedGoal) ||
                        !selectedProduct ||
                        !estimatedUnitsSoldPerMonth ||
                        (selectedGoal === ProposalGoal.EVENT && !eventDate) ||
                        !recommendationCreatorCounts
                      }
                      data-cy={'generateRecommendationButton'}
                    >
                      Generate creator recommendation
                    </Button>
                  </Grid>
                </Tooltip>
                {!!savedRecommendation && !isDifferent && (
                  <Alert
                    variant={'outlined'}
                    severity={'success'}
                    icon={<CheckCircleOutlined color={'success'} />}
                    sx={{ py: 0, px: 1 }}
                    data-cy={'recommendationMessage'}
                  >
                    <Typography variant={'label3'} flexShrink={1} color={'black'}>
                      {savedRecommendation.recommendationMessage}
                    </Typography>
                  </Alert>
                )}
              </Stack>
            )}

            <CreatorPricingInputList />

            {isAdminView && (
              <Box mt={2}>
                <OverridePricingSwitch brandPricing={pricing} />
              </Box>
            )}
          </Question>

          {isSelfServiceDisabled && managedServiceFeeAmount && (
            <Alert severity={'info'} variant={'outlined'} data-cy="managedCreatorsWarning">
              <Typography color={'black'} variant={'label3'}>
                Campaigns with premium UGC or social creators require a {managedServiceFeeAmount} managed service fee
                and cannot be scheduled through Momentum. Reach out to your sales representative to learn more about
                scheduling a campaign with social creators.
              </Typography>
            </Alert>
          )}

          {isAdminView && <ExtraFeesForm />}

          {isEstimatedUnitSoldPerMonthError && (
            <Alert variant={'outlined'} severity={'error'} color={'error'}>
              <Typography color={'black'} variant={'label3'}>
                You must enter your estimated units sold in order to proceed to the next step.
              </Typography>
            </Alert>
          )}
          {recommendedLaunchDate && recommendedLaunchDate.toISODate()! < minLaunchDate.date.toISODate()! && (
            <Alert variant={'outlined'} severity={'warning'} color={'warning'}>
              <Typography color={'black'} variant={'label3'}>
                The total creators in this campaign cannot be completed by the desired holiday or promo. Your creators
                will continue to join this campaign after your key date.
              </Typography>
            </Alert>
          )}
          {proposalRecommendationFlag && !!warnings.length && (
            <Stack spacing={1}>
              {warnings.map(w => (
                <Alert key={w.message} variant={'outlined'} severity={'warning'} color={'warning'}>
                  <Typography color={'black'} variant={'label3'}>
                    {w.message}
                  </Typography>
                </Alert>
              ))}
            </Stack>
          )}
        </Stack>
      )}
      <Stack direction={'row'} mt={4} justifyContent={'flex-end'} alignItems={'top'}>
        <Stack spacing={1}>
          {selfServiceFlag && !isSelfServiceSupported && (
            <Alert severity={'info'} variant={'outlined'}>
              <Typography color={'black'} variant={'label3'}>
                Self service is unavailable for this campaign type.
              </Typography>
            </Alert>
          )}
        </Stack>

        <Box ml={'auto'} pl={2} alignSelf={'end'}>
          {selfServiceFlag && isSelfServiceSupported && !isSelfServiceDisabled ? (
            <NextButton disabled={isSelfServiceDisabled} />
          ) : (
            <SubmitButton variant={'contained'} disableOnDirty={false} data-cy="saveProposal" sx={{ minWidth: 130 }}>
              Save proposal
            </SubmitButton>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}

const OverridePricingSwitch = ({ brandPricing }: { brandPricing?: StorePricing }) => {
  const { setValue, getValues, watch } = useFormContext()

  const isOverrideEnabled = watch('isOverridePricing')
  const resetPricing = () => {
    setValue(
      'creatorPricing',
      getValues('creatorPricing').map((p: CreatorPricing) => ({
        ...p,
        price:
          brandPricing?.creatorPricing.find(c => c.type === p.type)?.price ||
          brandPricing?.creatorPricing.find(c => c.type === p.type)?.fallbackPrice ||
          DEFAULT_PRICING[p.type].default
      }))
    )
    setValue('isOverridePricing', false)
  }

  const setOverrideEnabled = () => {
    setValue('isOverridePricing', true)
  }

  return (
    <Stack direction={'row'} spacing={2}>
      <Button variant={'text'} disabled={isOverrideEnabled} onClick={setOverrideEnabled}>
        Override pricing
      </Button>
      {!!isOverrideEnabled && (
        <Button variant={'text'} onClick={resetPricing}>
          Reset to default
        </Button>
      )}
    </Stack>
  )
}

const CampaignGoalSelector = ({ isAgency }: { isAgency?: boolean }) => {
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')
  const holidayCampaignTypeFlag = useFlag('HolidayCampaignType')
  const marketResearchTypeFlag = useFlag('MarketResearchType')
  const goalTypes = [
    ProposalGoal.PRODUCT_LAUNCH,
    ...(proposalRecommendationFlag ? [ProposalGoal.EXISTING_PRODUCT] : []),
    ProposalGoal.BOOST_RATING,
    ...(holidayCampaignTypeFlag ? [ProposalGoal.EVENT] : []),
    ...(!isAgency ? [ProposalGoal.EVERGREEN] : []),
    ...(marketResearchTypeFlag ? [ProposalGoal.MARKET_RESEARCH] : [])
  ]

  const [hoveredType, setHoveredType] = useState<ProposalGoal>()

  const { control } = useFormContext<ProposalCreateForm>()
  const {
    field: { value, onChange }
  } = useController<ProposalCreateForm, 'goal'>({ name: 'goal', control })

  const typeInformationToDisplay = get(CampaignGoalInformation, hoveredType || value)

  return (
    <Stack direction={'row'} spacing={2}>
      <Stack spacing={1} flexGrow={1}>
        {goalTypes.map(type => (
          <CampaignGoalOption
            key={type}
            type={type}
            selected={value === type}
            isHover={hoveredType === type}
            onClick={() => onChange(type)}
            onMouseEnter={() => setHoveredType(type)}
            onMouseLeave={() => setHoveredType(undefined)}
          />
        ))}
      </Stack>
      {typeInformationToDisplay && (
        <Stack spacing={2} width={'50%'}>
          <Stack spacing={1}>
            <Typography variant={'label1'}>{typeInformationToDisplay.name}</Typography>
            <Typography variant={'label3'} color={'grey.A700'} minHeight={110}>
              {typeInformationToDisplay.description}
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant={'label1'}>Good for</Typography>
            <Stack spacing={1} alignItems={'self-start'} minHeight={90}>
              {typeInformationToDisplay.benefits.map(b => (
                <Box key={b} bgcolor={'primary.lighter'} px={1} borderRadius={'4px'}>
                  <Typography variant={'label3'} color={'primary'}>
                    {b}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

const CampaignGoalOption = ({
  type,
  onClick,
  selected,
  isHover,
  onMouseEnter,
  onMouseLeave
}: {
  type: ProposalGoal
  selected?: boolean
  isHover?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick: () => void
}) => {
  return (
    <Stack
      data-cy={`campaignGoalOption-${type}`}
      direction={'row'}
      alignItems={'center'}
      spacing={2}
      py={0.5}
      px={1}
      borderRadius={'4px'}
      bgcolor={t => (selected ? t.palette.primary.lighter : isHover ? t.palette.grey.A100 : 'white')}
      sx={{ cursor: 'pointer' }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        bgcolor={selected ? 'primary.main' : 'grey.A100'}
        borderRadius={'4px'}
        borderColor={selected ? 'primary.main' : 'grey.A200'}
        sx={{ borderWidth: 1, borderStyle: 'solid' }}
        p={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {CampaignGoalTypeToIcon[type]?.({
          fontSize: 'medium',
          sx: { display: 'block', color: selected ? 'white' : 'black' }
        })}
      </Box>
      <Typography variant={'label2'} color={selected ? 'primary' : 'black'}>
        {CampaignGoalInformation[type]?.name}
      </Typography>
    </Stack>
  )
}

const SelectedProduct = ({ product, onRemove }: { product: Product; onRemove: () => void }) => {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} data-cy="selected-product" display={'flex'}>
      <CheckCircleOutlined color={'success'} />
      <Typography variant={'label3'}>{product?.name}</Typography>

      {!product?.skuId && (
        <Chip
          icon={<PackagePlusIcon color={'primary'} />}
          sx={theme => ({
            background: theme.palette.primary.lighter,
            color: theme.palette.primary.main
          })}
          size={'small'}
          label="Placeholder product"
        />
      )}
      <Cancel
        onClick={onRemove}
        fontSize={'mSmall'}
        sx={{ cursor: 'pointer', color: t => t.palette.grey.A400, pl: 1 }}
      />
    </Stack>
  )
}

const CreatorPricingInputList = () => {
  const { watch } = useFormContext<ProposalCreateForm>()
  const pricing = watch('creatorPricing')
  const { warnings } = useRecommendation()
  const proposalRecommendationFlag = useFlag('ProposalRecommendation')
  const theme = useTheme()

  return (
    <Stack border={`1px solid ${theme.palette.primary.lighter}`} borderBottom={'none'} borderRadius={'4px'}>
      {pricing.map(p => (
        <CreatorPricingInput
          key={p.type}
          type={p.type}
          warning={proposalRecommendationFlag && warnings.some(w => w.type === p.type)}
        />
      ))}
    </Stack>
  )
}

const RatingGoalQuestion = () => {
  const { formState } = useFormContext<ProposalCreateForm>()

  const error = formState.errors?.ratingGoal?.message

  return (
    <Stack spacing={1}>
      <NumberInput
        name={'ratingGoal'}
        placeholder={'Desired rating'}
        returnAsNumber
        allowNegative={false}
        hideError
        error={!!error}
        max={5}
      />
      {!!error && (
        <Alert variant={'outlined'} severity={'error'} color={'error'}>
          <Typography color={'black'} variant={'label3'}>
            {error}
          </Typography>
        </Alert>
      )}
    </Stack>
  )
}

const NextButton = ({ disabled }: { disabled?: boolean }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { watch } = useFormContext<ProposalCreateForm>()
  const data = watch()

  const handleNext = () => {
    navigate([...pathname.split('/').slice(0, -1), ROUTE_NAMES_PROPOSAL.TIMELINE].join('/'))
  }

  const totalCreators = sumBy(data.creatorPricing || [], cp => cp.numCreators)

  return (
    <Button
      variant={'contained'}
      onClick={handleNext}
      disabled={disabled || !creatorProductsFormValidationSchema.isValidSync(data) || !totalCreators}
      endIcon={<ArrowForwardOutlined />}
      data-cy={'nextButton'}
    >
      Next
    </Button>
  )
}

const CreatorPricingInput = ({ type, warning }: { type: PricingCreatorType; warning?: boolean }) => {
  const { isAdminView } = useUserSessionContext()
  const { setValue, getValues } = useFormContext<ProposalCreateForm>()
  const { brand } = useBrandContext()

  const creatorPricing = useWatch<ProposalCreateForm>({ name: `creatorPricing` }) as ProposalCreatorPricingInput[]
  const index = creatorPricing.findIndex(p => p.type === type)

  const [startingValue, setStartingValue] = useState<number>(getValues(`creatorPricing.${index}.numCreators`))
  const numCreators = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}.numCreators` }) as number
  const hidden = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}.hidden` }) as boolean

  const data = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}` }) as ProposalCreatorPricingInput

  useEffect(() => {
    if (numCreators !== startingValue) {
      const isDailyScheduling = getValues('isDailyScheduling')
      const creatorPricing = getValues('creatorPricing')
      const goal = getValues('goal')
      const eventDate = getValues('eventDate')
      const autoBuyDistribution = keyBy(getAutoBuysDistribution({ creatorPricing }), 'type')

      setStartingValue(numCreators)

      const creatorPricingWithSchedule = creatorPricing.map(c => ({
        ...c,
        schedule: isDailyScheduling
          ? autoBuyDistribution[c.type]?.dailySchedule
          : toWeeklyPartition(autoBuyDistribution[c.type]?.dailySchedule)
      }))
      setValue('creatorPricing', creatorPricingWithSchedule)

      handleRecommendedDates({
        goal,
        creatorPricingWithSchedule,
        brandRegion: brand.region,
        eventDate,
        setValue
      })
    }
  }, [numCreators])

  useEffect(() => {
    if (type === PricingCreatorType.SOCIAL) {
      const numCreators = data.numCreators
      const socialNumCreators = sumBy(data.socialCreatorPricing, 'numCreators')

      if (numCreators !== socialNumCreators) {
        data?.socialCreatorPricing?.forEach((pricing, socialIndex) => {
          const type = pricing.type

          if ([CreatorType.IG, CreatorType.TT].includes(type)) {
            const creatorType = pricing.type as CreatorType.IG | CreatorType.TT

            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.numCreators`,
              getSocialCreatorDistribution(numCreators, creatorType)
            )
            setValue(
              `creatorPricing.${index}.socialCreatorPricing.${socialIndex}.schedule`,
              pricing.schedule.map(s => getSocialCreatorDistribution(s, creatorType))
            )
          }
        })
      }
    }
  }, [type, data, setValue, index])

  if (hidden) {
    // only admins are allowed to see hidden creators
    return isAdminView ? <AddCreatorPricingInput type={type} index={index} /> : null
  }

  return <EditCreatorPricingInput type={type} warning={warning} index={index} />
}

const AddCreatorPricingInput = ({ type, index }: { type: PricingCreatorType; index: number }) => {
  const { setValue } = useFormContext<ProposalCreateForm>()

  const handleAddCreatorPricing = () => {
    setValue(`creatorPricing.${index}.hidden`, false)
  }

  return (
    <Box
      width={'100%'}
      sx={t => ({
        borderBottom: `1px solid ${t.palette.primary.lighter}`,
        borderRight: `1px solid ${t.palette.primary.lighter}`
      })}
      py={1}
      display={'flex'}
    >
      <Stack direction={'row'} px={1}>
        <IconButton
          size={'small'}
          onClick={handleAddCreatorPricing}
          color="primary"
          data-cy={`addCreatorPricing-${type}`}
        >
          <AddCircleRounded />
        </IconButton>
        <Typography variant={'label2'} alignSelf={'center'} color={theme => theme.palette.grey.A400} px={1.5}>
          {CREATOR_TYPE_PRICING_CONFIG[type]?.title}
        </Typography>
      </Stack>
    </Box>
  )
}

const EditCreatorPricingInput = ({
  type,
  warning,
  index
}: {
  type: PricingCreatorType
  warning?: boolean
  index: number
}) => {
  const { isAdminView } = useUserSessionContext()
  const { setValue } = useFormContext<ProposalCreateForm>()

  const isOverridePricing = useWatch<ProposalCreateForm, 'isOverridePricing'>({ name: 'isOverridePricing' })
  const data = useWatch<ProposalCreateForm>({ name: `creatorPricing.${index}` }) as ProposalCreatorPricingInput

  const cost = data.numCreators * data.price

  const handleRemoveCreatorPricing = () => {
    setValue(`creatorPricing.${index}.numCreators`, 0)
    setValue(`creatorPricing.${index}.hidden`, true)
  }

  return (
    <Stack direction={'row'}>
      <Box
        width={'100%'}
        sx={theme => ({
          borderBottom: `1px solid ${theme.palette.primary.lighter}`,
          borderRight: `1px solid ${theme.palette.primary.lighter}`
        })}
        px={1}
        minHeight={96}
        display={'flex'}
      >
        <Stack direction={'row'}>
          <Box width={40} py={0.5}>
            {isAdminView && MANAGED_SERVICE_CREATOR_TYPES.includes(type) && (
              <IconButton
                size={'small'}
                onClick={handleRemoveCreatorPricing}
                disableRipple
                color="default"
                data-cy={`removeCreatorPricing-${type}`}
              >
                <CancelRounded />
              </IconButton>
            )}
          </Box>
          <Grid container justifyContent={'space-between'} py={1}>
            <Grid item xs={8} alignSelf={'center'}>
              <Stack>
                <Typography variant={'label2'}>{CREATOR_TYPE_PRICING_CONFIG[type]?.title}</Typography>
                <Typography variant={'body1'} color={'grey.A700'}>
                  {CREATOR_TYPE_PRICING_CONFIG[type]?.description}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={'auto'} alignSelf={'center'}>
              <Stack direction={'row'}>
                {isOverridePricing ? (
                  <Grid item alignSelf={'center'} px={1} pb={0.5}>
                    <Stack alignItems={'center'} spacing={1}>
                      <NumberInput
                        sx={{ width: '45px', height: '24px', px: 0, py: 0, textAlign: 'center' }}
                        name={`creatorPricing.${index}.price`}
                        data-cy={`creatorPricing.${index}.price`}
                        decimalScale={2}
                        returnAsNumber
                        allowNegative={false}
                      />
                      <Typography variant={'caption2'}>credits</Typography>
                    </Stack>
                  </Grid>
                ) : (
                  <Grid item alignSelf={'center'} px={1}>
                    <Stack spacing={0.5} alignItems={'center'}>
                      <Typography variant={'label4'} pb={0.5}>
                        {data.price.toLocaleString()}
                      </Typography>
                      <Typography variant={'caption2'}>credits</Typography>
                    </Stack>
                  </Grid>
                )}
                <Grid item alignSelf={'center'} px={1}>
                  <Close fontSize={'mSmall'} />
                </Grid>
                <Grid item alignSelf={'center'} px={1} pb={0.5}>
                  <Stack alignItems={'center'} spacing={1}>
                    <NumCreatorsInput index={index} warning={warning} />
                    <Typography variant={'caption2'}>creators</Typography>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box
        bgcolor={'primary.lightest'}
        display={'flex'}
        sx={theme => ({
          borderBottom: `1px solid ${theme.palette.primary.lighter}`,
          borderLeft: 'none',
          width: 100
        })}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack alignItems={'center'} px={1} pt={1} spacing={0.5} pb={0.5}>
          <Typography variant={'label2'}>{cost.toLocaleString()}</Typography>
          <Typography variant={'caption2'}>credits</Typography>
        </Stack>
      </Box>
    </Stack>
  )
}

const NumCreatorsInput = ({ index, warning }: { index: number; warning?: boolean }) => {
  return (
    <NumberInput
      focused={warning}
      color={warning ? 'warning' : 'primary'}
      sx={{
        width: '45px',
        height: '24px',
        px: 0,
        py: 0,
        textAlign: 'center'
      }}
      name={`creatorPricing.${index}.numCreators`}
      decimalScale={0}
      returnAsNumber
      allowNegative={false}
      max={5000}
    />
  )
}

// Sets the recommended launch date and launch date for Holiday/Promo (EVENT) proposals
const handleRecommendedDates = ({
  goal,
  creatorPricingWithSchedule,
  brandRegion,
  eventDate,
  setValue
}: {
  goal: ProposalGoal
  creatorPricingWithSchedule: ProposalCreatorPricingInput[]
  eventDate?: DateTime
  brandRegion: Region
  setValue: UseFormSetValue<ProposalCreateForm>
}) => {
  if (goal === ProposalGoal.EVENT && eventDate) {
    const sumCreators = sumBy(creatorPricingWithSchedule, 'numCreators')
    if (!sumCreators) {
      setValue('recommendedLaunchDate', undefined)
      return
    }
    const numDaysToComplete = max(
      creatorPricingWithSchedule.map(cp => cp.schedule?.map(s => (s > 0 ? 1 : 0)).lastIndexOf(1))
    )
    if (numDaysToComplete != null) {
      const recommendedLaunchDate = eventDate.minus({ days: numDaysToComplete + 1 })
      /*
       If the recommended launch date is on a weekend or is on a holiday, we will move it to be 1 business day earlier
       so that it will still finish before the Holiday/Promo date
       */

      const safeRecommendedLaunchDate =
        isWeekend(recommendedLaunchDate) || isHoliday(recommendedLaunchDate)
          ? minusBusinessDays(recommendedLaunchDate, 1)
          : recommendedLaunchDate

      setValue('recommendedLaunchDate', safeRecommendedLaunchDate)

      const minLaunchDate = getMinLaunchDate(brandRegion)
      // If the recommended date is before the earliest possible date, set the launch date to the max of the two.
      // A warning will be displayed to the user indicating this.
      setValue('launchDate', max([safeRecommendedLaunchDate, minLaunchDate.date])!)
    }
  }
}
