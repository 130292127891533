import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_ECOMMERCE } from '@momentum/routes/RouteNames'
import { Chip, Tab, Tabs, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Stack } from '@mui/system'
import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useMemo } from 'react'
import { Link as RouterLink, useLocation, useMatch, useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  tab: {
    textTransform: 'none'
  }
})

export const TabBar = () => {
  const { isAdminView } = useUserSessionContext()
  const navigate = useNavigate()
  const base = [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.ECOMMERCE].join('/')
  const { pathname } = useLocation()
  const sponsoredRankFlag = useFlag('SponsoredRank')

  const classes = useStyles()

  const baseMatch = useMatch(base)

  const currentTab = useMemo(() => {
    if (baseMatch) {
      return ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SEO_V2
    } else {
      return pathname.split('/').pop()!
    }
  }, [baseMatch, pathname, sponsoredRankFlag])

  useEffect(() => {
    if (baseMatch) {
      navigate(currentTab, { replace: true })
    }
  }, [baseMatch, currentTab])

  return (
    <Tabs value={currentTab} variant="scrollable">
      {isAdminView && (
        <Tab
          data-cy={'ecommerceTrafficTab'}
          label={<TabTitle title="Traffic" chip="Admin" />}
          value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.TRAFFIC}
          to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.TRAFFIC}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      <Tab
        data-cy={'ecommerceSeoV2Tab'}
        label={'SEO'}
        value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SEO_V2}
        to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SEO_V2}
        component={RouterLink}
        className={classes.tab}
      />
      {(isAdminView || sponsoredRankFlag) && (
        <Tab
          data-cy={'ecommerceSponsoredRankTab'}
          label={!sponsoredRankFlag ? <TabTitle title="Sponsored rank" chip="Admin" /> : 'Sponsored rank'}
          value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SPONSORED_RANK}
          to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SPONSORED_RANK}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      <Tab
        data-cy={'ecommerceReviewsTab'}
        label="Reviews"
        value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS}
        to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS}
        component={RouterLink}
        className={classes.tab}
      />
      {isAdminView && (
        <Tab
          data-cy={'ecommerceReviewsV2Tab'}
          label={<TabTitle title="Reviews V2" chip="Admin" />}
          value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS_V2}
          to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS_V2}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      {isAdminView && (
        <Tab
          data-cy={'ecommerceSalesTab'}
          label={<TabTitle title="Sales" chip="Admin" />}
          value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SALES}
          to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SALES}
          component={RouterLink}
          className={classes.tab}
        />
      )}
      <Tab
        data-cy={'ecommerceFeedbackTab'}
        label="Feedback"
        value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.FEEDBACK}
        to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.FEEDBACK}
        component={RouterLink}
        className={classes.tab}
      />
      <Tab
        data-cy={'ecommercePerformanceTab'}
        label={<TabTitle title="Benchmarking" />}
        value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.PERFORMANCE}
        to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.PERFORMANCE}
        component={RouterLink}
        className={classes.tab}
      />
      {isAdminView && (
        <Tab
          data-cy={'ecommerceBenchmarkTab'}
          label={<TabTitle title="Benchmark V2" chip="Admin" />}
          value={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.BENCHMARK}
          to={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.BENCHMARK}
          component={RouterLink}
          className={classes.tab}
        />
      )}
    </Tabs>
  )
}

const TabTitle = ({ title, chip }: { title: string; chip?: string }) => (
  <Stack direction={'row'} spacing={1} alignItems={'center'}>
    <Typography variant="inherit">{title}</Typography>
    {!!chip && <Chip label={chip} color="secondary" size="small" />}
  </Stack>
)
