import MoreMenu, { MoreMenuItem } from '@momentum/components/more-menu'
import Row from '@momentum/components/row'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { StepProgress } from '@momentum/routes/proposals-create'
import { useCreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { Close, HighlightOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { ProposalStatus } from '@productwindtom/shared-momentum-zeus-types'
import { SubmitButton, SwitchInput, TextInput } from '@productwindtom/ui-base'
import { useFlag } from '@unleash/proxy-client-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { creatorPricingValidation } from '../../validations'

export const OverviewHeader = () => {
  const { setIsFinancialSummaryView, isFinancialSummaryView } = useCreateProposalContext()
  const { isAdminView } = useUserSessionContext()
  const goal = useWatch<ProposalCreateForm, 'goal'>({ name: 'goal' })
  const selectedProductId = useWatch<ProposalCreateForm, 'productId'>({ name: 'productId' })

  const [currentGoal, setCurrentGoal] = useState(goal)
  const [currentProductId, setCurrentProductId] = useState(selectedProductId)

  const { setValue } = useFormContext<ProposalCreateForm>()

  const saveButtonRef = useRef<HTMLButtonElement>(null)

  const creatorPricing = useWatch<ProposalCreateForm, 'creatorPricing'>({ name: 'creatorPricing' })
  const creatorPricingError = useMemo(() => {
    try {
      creatorPricingValidation.validateSync(creatorPricing, {})
    } catch (err: any) {
      if (err.type === 'numCreators') {
        return err.message
      }
    }
  }, [creatorPricing])

  useEffect(() => {
    if (currentGoal !== goal) {
      setCurrentGoal(goal)
    }
  }, [goal])

  useEffect(() => {
    if (!currentProductId) {
      setValue('productVariationSkus', null)
    }

    if (selectedProductId !== currentProductId) {
      setCurrentProductId(selectedProductId)
    }
  }, [selectedProductId, currentProductId, setValue])

  return (
    <Box position={'sticky'} top={0} bgcolor={'white'} py={3} zIndex={2}>
      <Container>
        <Stack direction={'row'} spacing={2} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Stack spacing={4}>
            {!isFinancialSummaryView && isAdminView && <StepProgress />}
            <Stack spacing={1}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
                <BrandTitle />
                <ActionsMenu onSave={() => saveButtonRef?.current?.click()} />
              </Stack>

              <Stack direction={'row'} spacing={1}>
                <SubmitButton
                  data-cy={'headerSaveButton'}
                  variant={'outlined'}
                  size={'small'}
                  disableOnDirty={false}
                  ref={saveButtonRef}
                >
                  Save proposal
                </SubmitButton>
                {isFinancialSummaryView && (
                  <Button onClick={() => setIsFinancialSummaryView(false)}>Back to proposal</Button>
                )}
                {isAdminView && (
                  <Stack direction={'row'} alignItems={'center'}>
                    <SwitchInput name={'isVisibleToClient'} size={'small'} />
                    <Typography variant={'body1'}>Visible to client</Typography>
                  </Stack>
                )}
              </Stack>
              {creatorPricingError && (
                <Row spacing={0.5}>
                  <HighlightOff color="error" />
                  <Typography color="error" variant="label3">
                    {creatorPricingError}
                  </Typography>
                </Row>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const BrandTitle = () => {
  return (
    <Stack spacing={1} direction={'row'} flex={1}>
      <TextInput name={'title'} data-cy="proposalTitle" fullWidth sx={{ minWidth: 410 }} />
    </Stack>
  )
}

const ActionsMenu = ({ onSave }: { onSave: () => void }) => {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isDuplicateProposalOpen, setIsDuplicateProposalOpen] = useState(false)

  const { proposal, saving, onDeleteProposal, onDuplicateProposal, setIsFinancialSummaryView, isFinancialSummaryView } =
    useCreateProposalContext()

  const { isAdminView } = useUserSessionContext()
  const methods = useFormContext<ProposalCreateForm>()
  const {
    formState: { isValid, isSubmitting }
  } = methods

  const moreMenuItems: MoreMenuItem[] = []
  const financialSummaryFlag = useFlag('FinancialSummary')
  const duplicateProposalsFlag = useFlag('DuplicateProposals')

  if (!!proposal && proposal.status !== ProposalStatus.SUBMITTED) {
    moreMenuItems.push(
      ...[
        {
          menuItemProps: {
            onClick: () => setIsConfirmDeleteOpen(true),
            children: 'Delete proposal'
          }
        }
      ]
    )
  }

  if (duplicateProposalsFlag && !!proposal) {
    moreMenuItems.push(
      ...[
        {
          menuItemProps: {
            onClick: () => setIsDuplicateProposalOpen(true),
            children: 'Duplicate proposal'
          }
        }
      ]
    )
  }

  if (isAdminView && financialSummaryFlag && !isFinancialSummaryView) {
    moreMenuItems.push({
      menuItemProps: {
        onClick: () => setIsFinancialSummaryView(true),
        children: 'View financial summary'
      }
    })
  }

  if (moreMenuItems.length) {
    moreMenuItems.push({
      isDivider: true
    })
  }

  moreMenuItems.push({
    menuItemProps: {
      onClick: () => onSave(),
      disabled: !isValid || isSubmitting || saving,
      children: 'Save proposal'
    }
  })

  return (
    <>
      <MoreMenu items={moreMenuItems} />
      <ConfirmDeleteDialog
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        onConfirm={async () => await onDeleteProposal()}
      />
      <DuplicateProposalDialog
        open={isDuplicateProposalOpen}
        onClose={() => setIsDuplicateProposalOpen(false)}
        onConfirm={async (title: string) => {
          await onDuplicateProposal(title, methods)
          setIsDuplicateProposalOpen(false)
        }}
      />
    </>
  )
}

const ConfirmDeleteDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
}) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleConfirm = async () => {
    setIsDeleting(true)
    await onConfirm()
    setIsDeleting(false)
  }

  return (
    <Dialog open={open} sx={{ p: 4 }} PaperProps={{ sx: { p: 3 } }} maxWidth={'xs'}>
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
          <Typography variant={'h4'}>Are you sure you want to delete this campaign proposal?</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Typography variant={'subtitle2'}>This proposal will be deleted from Momentum.</Typography>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button variant={'text'} disabled={isDeleting} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={isDeleting}
            variant={'contained'}
            onClick={handleConfirm}
            data-cy="confirmDeleteProposal"
          >
            Delete proposal
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

const DuplicateProposalDialog = ({
  open,
  onClose,
  onConfirm
}: {
  open: boolean
  onClose: () => void
  onConfirm: (title: string) => Promise<void>
}) => {
  const { proposal } = useCreateProposalContext()

  const [isSaving, setIsSaving] = useState(false)
  const [title, setTitle] = useState(`Copy - ${proposal?.title}`)

  useEffect(() => {
    setTitle(`Copy - ${proposal?.title}`)
  }, [proposal?.title])

  const handleConfirm = async () => {
    const trimmed = title.trim()
    if (trimmed) {
      setIsSaving(true)
      await onConfirm(trimmed)
      setIsSaving(false)
    }
  }

  return (
    <Dialog open={open} sx={{ p: 4 }} PaperProps={{ sx: { p: 3 } }} maxWidth={'xs'}>
      <Stack spacing={3}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
          <Typography variant={'h4'}>Are you sure you want to duplicate this proposal?</Typography>
          <IconButton size={'small'} onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Typography variant={'subtitle2'}>
          This proposal will be duplicated and added to your proposals list.
        </Typography>
        <TextField value={title} onChange={event => setTitle(event.target.value)} />
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
          <Button variant={'text'} disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={isSaving}
            variant={'contained'}
            onClick={handleConfirm}
            disabled={!title.trim()}
            data-cy="confirmDuplicateProposal"
          >
            Duplicate proposal
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}
