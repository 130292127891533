import { ProposalGoal, STORE_TO_LOCALE } from '@productwindtom/shared-momentum-zeus-types'
import { useState } from 'react'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, Chip } from '@mui/material'
import { Box } from '@mui/system'
import { getCdnImageUrl, noProductImageAlt } from '@momentum/utils/imageUtils'
import { StoreToIcon, StoreToFlagIcon } from '@momentum/utils/storeIcons'
import { toCurrencyStringCents, getCurrencySymbol } from '@productwindtom/shared-ws-currency'
import { Close, OpenInNewOutlined, EditOutlined, Circle, Star } from '@mui/icons-material'
import { NumberInput } from '@productwindtom/ui-base'
import * as yup from 'yup'
import { useCreateProposalContext } from '../context/CreateProposalContext'
import { ProposalCreateForm } from '../types'
import { keyBy, maxBy } from 'lodash'
import { SelectedProduct } from '@momentum/routes/proposals-create/context/types'
import { notEmpty } from '@productwindtom/shared-node'
import PackagePlusIcon from '@momentum/components/icons/package-plus'

const Separator = () => <Circle sx={{ height: '2px', width: '2px', color: theme => theme.palette.grey.A700 }} />

const priceChangeSchema = yup.object({
  productPriceOverride: yup.number().nullable().optional()
})

export const SelectedProductInformation = ({
  product,
  productPriceOverride,
  goal,
  onPriceOverride,
  readonly
}: {
  product: SelectedProduct
  productPriceOverride?: number
  goal?: ProposalGoal
  onPriceOverride?: (value?: number | null) => void
  readonly?: boolean
}) => {
  const [priceOverrideOpen, setPriceOverrideOpen] = useState(false)
  const { brand } = useBrandContext()
  const { selectedProduct, selectableProducts } = useCreateProposalContext()
  const productVariationSkus = useWatch<ProposalCreateForm, 'productVariationSkus'>({ name: 'productVariationSkus' })

  const keyedProducts = keyBy(selectableProducts, 'id')

  const selectedVariations =
    selectedProduct &&
    !!productVariationSkus?.length &&
    selectedProduct?.childrenIds
      ?.map(id => keyedProducts[id])
      .filter(notEmpty)
      .filter(v => v.skuId && productVariationSkus.includes(v.skuId))

  const highestPrice = maxBy(selectedVariations || [], 'priceCents')?.priceCents

  const handlePriceOverrideClose = () => {
    setPriceOverrideOpen(false)
  }
  const submit = async (data: { productPriceOverride?: number | null }) => {
    onPriceOverride?.(data.productPriceOverride)
    handlePriceOverrideClose()
  }

  const methods = useForm<{ productPriceOverride?: number | null }>({
    mode: 'onChange',
    resolver: yupResolver(priceChangeSchema),
    defaultValues: { productPriceOverride }
  })

  return (
    <Box borderRadius={'4px'} border={t => `1px solid ${t.palette.grey.A200}`}>
      <Stack direction={'row'} alignItems={'center'} spacing={2} px={2} py={1}>
        <Box
          width={'64px'}
          height={'64px'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'4px'}
          border={'1px solid #F3F0F0'}
        >
          <img
            src={getCdnImageUrl(product.image) || '/images/no-product.png'}
            alt={'Product Image'}
            onError={noProductImageAlt}
            loading="lazy"
            width="100%"
            height={'100%'}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Stack spacing={1} flex={1}>
          {!product?.skuId && (
            <Box>
              <Chip
                icon={<PackagePlusIcon color={'primary'} />}
                sx={theme => ({
                  background: '#EBEDF6',
                  color: theme.palette.primary.main
                })}
                size={'small'}
                label="Placeholder product"
              />
            </Box>
          )}
          <Stack direction={'row'} spacing={2}>
            {!!product.listingLink && (
              <Button
                href={product.listingLink}
                target={'_blank'}
                variant={'text'}
                startIcon={<OpenInNewOutlined fontSize={'mSmall'} />}
              >
                <Typography variant={'link2'}>View product</Typography>
              </Button>
            )}
            {!readonly && (
              <Button
                variant={'text'}
                onClick={() => setPriceOverrideOpen(true)}
                startIcon={<EditOutlined fontSize={'mSmall'} />}
              >
                <Typography variant={'link2'}>Edit product price</Typography>
              </Button>
            )}
          </Stack>

          <Typography
            variant={'label3'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineBreak: '2',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2
            }}
            data-cy="productName"
          >
            {product.name}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            {StoreToIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            <Separator />
            {StoreToFlagIcon[product.store]?.({ fontSize: 'mSmall', style: { verticalAlign: 'middle' } })}
            <Separator />
            <Typography variant={'body1'} color={'grey.A700'}>
              {productPriceOverride
                ? toCurrencyStringCents(productPriceOverride, STORE_TO_LOCALE[product.store])
                : toCurrencyStringCents(highestPrice || product.priceCents!, STORE_TO_LOCALE[product.store])}
              {productPriceOverride && (
                <Typography variant={'body1'} color={'grey.A700'}>
                  {' '}
                  (Edited)
                </Typography>
              )}
            </Typography>
            <Separator />
            {!!product?.skuId && (
              <>
                <Typography variant={'body1'} color={'grey.A700'}>
                  ASIN: {product.skuId}
                </Typography>
                <Separator />
              </>
            )}
            {!!product.ratingSummary?.rating && (
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Star fontSize={'mSmall'} color={'warning'} />
                <Typography variant={'body1'} color={'grey.A700'}>
                  {product.ratingSummary.rating}
                </Typography>
              </Stack>
            )}
            {!!product.ratingSummary?.rating && <Separator />}
            {!!product.childrenIds?.length && <Typography variant={'caption2'}>PARENT</Typography>}
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={priceOverrideOpen} onClose={handlePriceOverrideClose} fullWidth maxWidth={'xs'}>
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'h4'}>Edit product price</Typography>
            <IconButton size={'small'} onClick={handlePriceOverrideClose}>
              <Close fontSize={'mSmall'} />
            </IconButton>
          </Stack>
        </DialogTitle>

        <FormProvider {...methods}>
          <DialogContent>
            <Stack spacing={3}>
              <Typography variant={'subtitle2'}>This will edit the product price for this proposal only.</Typography>

              <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack spacing={2}>
                  <Typography>Original product price</Typography>
                  {product.priceCents != null && (
                    <Typography variant={'subtitle2'}>
                      {toCurrencyStringCents(product.priceCents, STORE_TO_LOCALE[product.store])}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={1}>
                  <Typography>New product price</Typography>
                  <NumberInput
                    name={'productPriceOverride'}
                    prefix={getCurrencySymbol(brand.region)}
                    decimalScale={2}
                    fullWidth
                    returnAsNumber
                    returnAsCents
                  />
                </Stack>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
                <Button onClick={handlePriceOverrideClose}>Cancel</Button>
                <Button
                  variant={'contained'}
                  onClick={methods.handleSubmit(submit)}
                  disabled={!methods.formState.isValid}
                >
                  Change product price
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </FormProvider>
      </Dialog>
    </Box>
  )
}
