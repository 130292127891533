import { yupResolver } from '@hookform/resolvers/yup'
import Loading from '@momentum/components/loading'
import ScrollToTop from '@momentum/components/scroll-to-top/scroll-to-top'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { ROUTES, ROUTE_NAMES_BRAND, ROUTE_NAMES_PROPOSAL } from '@momentum/routes/RouteNames'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { Proposal } from '@momentum/routes/brand/types'
import { CreateProposalContext } from '@momentum/routes/proposals-create/context/CreateProposalContext'
import {
  Product as MinimalProduct,
  getProduct,
  getProposal,
  requestExchangeRate
} from '@momentum/routes/proposals-create/context/queries'
import CreateProposalHeader from '@momentum/routes/proposals-create/modules/create-proposal-header'
import { EstimatedDeliverables } from '@momentum/routes/proposals-create/modules/estmated-deliverables'
import { OverviewHeader } from '@momentum/routes/proposals-create/modules/overview-header'
import { deleteProposal } from '@momentum/routes/proposals-create/mutations'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { useSaveProposal } from '@momentum/routes/proposals-create/useSaveProposal'
import { HUBSPOT_ACCOUNT_ID, proposalFormValidation } from '@momentum/routes/proposals-create/validations'
import {
  convertFromCreatorPricing,
  convertToCreatorPricing,
  generateContentRequirements,
  getMinLaunchDate,
  getProposalDefaultForRecommendationType
} from '@momentum/utils/proposalUtils'
import { getCreatorPricingStore } from '@momentum/utils/storeUtils'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { STORE_TO_CURRENCY, STORE_TO_RETAILER, getBrandStorePricing } from '@productwindtom/shared-momentum'
import {
  InvoicePaymentType,
  ProposalGoal,
  ProposalStatus,
  RecommendationType,
  SubscriptionLevel
} from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { Form } from '@productwindtom/ui-base'
import { captureException } from '@sentry/react'
import { useFlag } from '@unleash/proxy-client-react'
import { entries, groupBy, orderBy, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Outlet, generatePath, useLocation, useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as uuid from 'uuid'
import FinancialSummary from './modules/financial-summary'

export const ProposalsCreate = () => {
  const endOfMonthBillingFlag = useFlag('EndOfMonthBilling')
  const isDailyScheduling = useFlag('ReviewCreatorScheduleEditor')
  const { profile, agencies } = useUserSessionContext()
  const { company, brand, products, setProposals } = useBrandContext()
  const [defaultData, setDefaultData] = useState<ProposalCreateForm>()
  const [selectedProduct, setSelectedProduct] = useState<MinimalProduct>()
  const [isDeliverablesVisible, setIsDeliverablesVisible] = useState(false)
  const [proposal, setProposal] = useState<Proposal | undefined>()
  const [saving, setSaving] = useState(false)
  const [startingProduct, setStartingProduct] = useState<MinimalProduct>()

  const { saveProposal } = useSaveProposal(proposal?.id)
  const { saveProposal: duplicateProposal } = useSaveProposal()

  const params = useParams<{
    brandId: string
    id?: string
    productId?: string
    type?: RecommendationType
  }>()
  const navigate = useNavigate()
  const { pathname, state: locationState } = useLocation()
  const [searchParams] = useSearchParams()

  const [isFinancialSummaryView, setIsFinancialSummaryView] = useState(
    !!locationState?.isFinancialSummaryView || searchParams.get('isFinancialSummaryView') === 'true'
  )
  const { isAdminView } = useUserSessionContext()
  const createProposalMatch = useMatch(ROUTES.BRAND_CREATE_PROPOSAL)
  const editProposalMatch = useMatch(ROUTES.BRAND_CREATE_PROPOSAL_EDIT)
  const createFromRecommendationMatch = useMatch(ROUTES.BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION)
  const createProposalWinMatch = useMatch([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, ROUTE_NAMES_PROPOSAL.WIN].join('/'))

  const billingContact =
    company.subscriptionLevel === SubscriptionLevel.COMPANY
      ? brand.paymentBillingContact || company.paymentBillingContact
      : brand.paymentBillingContact

  const selectableProducts = useMemo(
    () =>
      orderBy(
        entries(
          groupBy(
            uniqBy(
              [...(products || []), startingProduct].filter(notEmpty).map(p => {
                const children = products?.filter(pp => pp.id !== p.id && pp.parentSkuId === p.id) || []
                return {
                  ...p,
                  // priceCents: p.priceCents || max(children.map(c => c.priceCents)),
                  childrenIds: children.map(pp => pp.id)
                }
              }),
              'id'
            ),
            s => s.skuId || 'NONE'
          )
        )
          // If we have duplicate ASINs, we will choose the one that references itself as a parent or does not reference another one of the same ASIN
          // If the sku references another sku of the same skuId, then we will get an endless loop.
          .flatMap(([skuId, vals]) =>
            skuId === 'NONE'
              ? vals
              : vals.length > 1
                ? vals.find(v => v.parentSkuId === v.id || vals.filter(vv => vv.id !== v.parentSkuId).length < 2)
                : vals[0]
          )
          .filter(notEmpty)
          .filter(p => p.childrenIds.length !== 1),
        i => (i.store?.startsWith('amazon') ? 1 : 0),
        'desc'
      ),

    [startingProduct, products]
  )

  useEffect(() => {
    if (createProposalMatch || editProposalMatch || createFromRecommendationMatch) {
      navigate([pathname, ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS].join('/'), { replace: true })
    }
  }, [createProposalMatch, editProposalMatch, createFromRecommendationMatch])

  const loadProposal = async (proposalId: string) => {
    const p = await getProposal(proposalId)

    setProposal(p)
    if (p) {
      const startingProduct = products?.find(product => product.id === p.productId) || p.product
      setStartingProduct(startingProduct)

      const creatorPricing = convertFromCreatorPricing(p.creatorPricing)
      setDefaultData({
        title: p.title || `New ${brand.name} campaign`,
        invoiceLink: '',
        ...p,

        managedServiceFeeEnabled: p.managedServiceFee != null,
        managedServiceFee: p.managedServiceFee,
        subscriptionFeeEnabled: p.subscriptionFee != null,
        subscriptionFee: p.subscriptionFee,
        subscriptionTerm: p.subscriptionTerm,

        eventDate: p.eventDate ? DateTime.fromISO(p.eventDate) : undefined,
        estimatedUnitsSoldPerMonth: p.estimatedUnitsSoldPerMonth,
        paymentBillingContact: p.paymentBillingContact || billingContact,
        billingContacts: p.billingContacts || [
          { name: 'billing@productwind.com', email: 'billing@productwind.com' },
          { name: `${profile.firstName} ${profile.lastName}`, email: profile.email }
        ],
        benchmarkProducts: p.benchmarkProducts || [],
        searchTerms: p.searchTerms || [],
        isVisibleToClient: p.status === ProposalStatus.PUBLISHED,
        launchDate: DateTime.fromISO(p.launchDate),

        invoiceDueDate: p.invoiceDueDate ? DateTime.fromISO(p.invoiceDueDate) : undefined,
        invoicePaidDate: p.invoicePaidDate ? DateTime.fromISO(p.invoicePaidDate) : undefined,
        invoiceProductCostDueDate: p.invoiceProductCostDueDate
          ? DateTime.fromISO(p.invoiceProductCostDueDate)
          : undefined,
        invoiceProductCostPaidDate: p.invoiceProductCostPaidDate
          ? DateTime.fromISO(p.invoiceProductCostPaidDate)
          : undefined,

        creatorPricing,
        contentRequirements: generateContentRequirements(creatorPricing),
        paymentType: p.paymentType || (endOfMonthBillingFlag ? InvoicePaymentType.END_OF_MONTH_INVOICE : undefined),
        exchangeRate: p.exchangeRate || 1,
        hubspotDealLink: p.hubspotDealId
          ? `https://app.hubspot.com/contacts/${HUBSPOT_ACCOUNT_ID}/deal/${p.hubspotDealId}`
          : undefined,

        customFeedbackQuestions: p.customFeedbackQuestions
      })

      setSelectedProduct(startingProduct)
    }
  }

  useEffect(() => {
    if (params.id) {
      loadProposal(params.id)
    } else if (params.productId && params.type) {
      handleRecommendationLoad(params.type, params.productId)
    } else {
      setDefaultData({
        title: `New ${brand.name} campaign`,
        brandId: brand.id,
        productId: '',
        goal: ProposalGoal.PRODUCT_LAUNCH,
        launchDate: getMinLaunchDate(brand.region).date,
        isVisibleToClient: !isAdminView,
        status: ProposalStatus.DRAFT,
        invoiceLink: '',
        isDailyScheduling: isDailyScheduling,
        creatorPricing: [],
        searchTerms: [],
        benchmarkProducts: [],
        paymentBillingContact: billingContact,
        billingContacts: [
          { name: 'billing@productwind.com', email: 'billing@productwind.com' },
          { name: `${profile.firstName} ${profile.lastName}`, email: profile.email }
        ],
        contentRequirements: [],
        paymentType: endOfMonthBillingFlag ? InvoicePaymentType.END_OF_MONTH_INVOICE : undefined,

        managedServiceFeeEnabled: false,
        managedServiceFee: null,
        subscriptionFeeEnabled: false,
        subscriptionFee: null,
        subscriptionTerm: null
      })
    }
  }, [params.id, params.productId, params.type])

  const handleRecommendationLoad = async (type: RecommendationType, productId: string) => {
    const product = products?.find(p => p.id === params.productId) || (await getProduct(productId))

    if (product) {
      setStartingProduct(product)
      const exchangeRate = await requestExchangeRate(STORE_TO_CURRENCY[product.store])

      const productPricingStore = getCreatorPricingStore(product.store)
      const pricing = getBrandStorePricing(
        brand.region,
        brand.pricing,
        company.pricing,
        (agencies.find(a => a.id === company.agencyId) ?? company.agency)?.clientPricing
      ).find(b => b.store === productPricingStore)

      const retailer = STORE_TO_RETAILER[product?.store]

      const defaultRecommendationValues = getProposalDefaultForRecommendationType(
        type,
        retailer,
        pricing?.creatorPricing ?? [],
        product,
        !!type,
        isDailyScheduling
      )

      const creatorPricing = convertFromCreatorPricing(
        convertToCreatorPricing(defaultRecommendationValues.creatorPricing)
      )

      setDefaultData({
        title: `New ${brand.name} campaign`,
        brandId: brand.id,
        status: ProposalStatus.DRAFT,
        isVisibleToClient: !isAdminView,
        productId: product.id,
        searchTerms: [],
        benchmarkProducts: [],
        paymentBillingContact: billingContact,
        billingContacts: [
          { name: 'billing@productwind.com', email: 'billing@productwind.com' },
          { name: `${profile.firstName} ${profile.lastName}`, email: profile.email }
        ],
        ...defaultRecommendationValues,
        isDailyScheduling: isDailyScheduling,
        creatorPricing,
        contentRequirements: generateContentRequirements(creatorPricing),
        paymentType: endOfMonthBillingFlag ? InvoicePaymentType.END_OF_MONTH_INVOICE : undefined,
        exchangeRate,

        managedServiceFeeEnabled: false,
        managedServiceFee: null,
        subscriptionFeeEnabled: false,
        subscriptionFee: null,
        subscriptionTerm: null
      })
      setSelectedProduct(product)
    }
  }

  const onDeleteProposal = async () => {
    if (proposal) {
      setSaving(true)
      await deleteProposal(proposal.id)
      setProposals(proposals => proposals?.filter(p => p.id !== proposal.id))
    }

    navigate(generatePath([ROUTES.BRAND, ROUTE_NAMES_BRAND.PROPOSALS].join('/'), { brandId: brand.id }))
    setSaving(false)
  }

  const onDuplicateProposal = async (title: string, methods: UseFormReturn<ProposalCreateForm>) => {
    if (defaultData) {
      setSaving(true)
      const input: ProposalCreateForm = {
        ...defaultData,
        id: uuid.v4(),
        title,
        status: ProposalStatus.DRAFT,
        invoiceDueDate: undefined,
        invoiceLink: undefined,
        invoicePaidDate: undefined,
        invoiceStatus: undefined,
        isVisibleToClient: false,
        totalCreditsPaid: undefined,
        hubspotDealLink: undefined
      }
      const newProposal = await duplicateProposal(input)
      toast(<Typography variant={'subtitle2'}>You have successfully duplicated this proposal!</Typography>, {
        type: 'success'
      })
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, pathname.split('/').pop()].join('/'), {
          brandId: brand?.id,
          id: newProposal.id
        }),
        {
          replace: true
        }
      )

      methods.reset({ id: newProposal.id, ...input })
    }
    setSaving(false)
  }

  const handleSubmit = async (submitValues: ProposalCreateForm, methods: UseFormReturn<ProposalCreateForm>) => {
    setSaving(true)
    try {
      const newProposal = await saveProposal(submitValues)
      toast(<Typography variant={'subtitle2'}>Campaign draft saved!</Typography>, { type: 'success' })
      navigate(
        generatePath([ROUTES.BRAND_CREATE_PROPOSAL_EDIT, pathname.split('/').pop()].join('/'), {
          brandId: brand?.id,
          id: newProposal.id
        }),
        {
          replace: true
        }
      )

      methods.reset({ id: newProposal.id, ...submitValues })
    } catch (e) {
      console.log(e)
      captureException(e)
      toast(<Typography variant={'subtitle2'}>Error saving draft</Typography>, { type: 'error' })
    }
    setSaving(false)
  }

  const productWithVariations = useMemo(() => {
    if (selectedProduct) {
      const { parentSkuId, id } = selectedProduct

      const variations = uniqBy(
        selectableProducts?.filter(p => [parentSkuId, id].includes(p.parentSkuId) || [parentSkuId, id].includes(p.id)),
        'id'
      )

      return {
        ...selectedProduct,
        variations
      }
    }
  }, [selectedProduct, products])

  if (!proposal && params.id) {
    return <Loading />
  }

  if (!defaultData) {
    return <Loading />
  }

  return (
    <CreateProposalContext.Provider
      value={{
        saving,
        proposal,
        selectedProduct: productWithVariations,
        setSelectedProduct,
        selectableProducts,
        onDeleteProposal,
        onDuplicateProposal,
        isDeliverablesVisible,
        setIsDeliverablesVisible,
        recommendationType: params.type,
        isFinancialSummaryView,
        setIsFinancialSummaryView
      }}
    >
      <ScrollToTop />
      <Stack width={'100%'}>
        <CreateProposalHeader brandName={brand.name} />
        <Form
          autocomplete={'off'}
          onSubmit={handleSubmit}
          defaultValues={defaultData}
          resolver={yupResolver(proposalFormValidation) as any}
        >
          {createProposalWinMatch ? (
            <Outlet />
          ) : (
            <Stack>
              <OverviewHeader />
              <Container>
                {isFinancialSummaryView ? (
                  <FinancialSummary />
                ) : (
                  <Grid container justifyContent={'space-between'}>
                    <Grid item xs={12} md={7} pr={{ xs: 0, md: 6 }} pt={4}>
                      <Outlet />
                    </Grid>
                    <Grid item xs={12} md={5} position={'sticky'} top={'50px'} height={'100%'} zIndex={10}>
                      <Box
                        borderRadius={'4px'}
                        boxShadow={'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)'}
                      >
                        <EstimatedDeliverables />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Container>
            </Stack>
          )}
        </Form>
      </Stack>
    </CreateProposalContext.Provider>
  )
}

export const ProposalCreateWithLoading = () => {
  // const { products } = useBrandContext()
  // if (!products) {
  //   return <Loading />
  // }

  return <ProposalsCreate />
}
export { SelectedProductInformation } from '@momentum/routes/proposals-create/common/SelectedProductInformation'
export { StepProgress } from '@momentum/routes/proposals-create/common/StepProgress'
