import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Stack, Typography } from '@mui/material'
import { getCampaignSeoMetrics } from '@productwindtom/shared-momentum'
import { Store } from '@productwindtom/shared-momentum-zeus-types'
import { sumBy } from 'lodash'
import { Metric } from '../../common/Metric'
import { useSeoContext } from '../context'

const MIN_AMAZON_CHOICE_BADGES = 3

export const OrganicRankSummary = () => {
  const { productSeoSummary, productKeywordSummaries } = useSeoContext()
  const { campaignDetails } = useCampaignContext()
  const { organicPageOneWinsSum, organicImprovedSlotsSum, searchVolumeSum } = getCampaignSeoMetrics(
    campaignDetails.startDate,
    productSeoSummary,
    productKeywordSummaries
  )

  const choiceBadgesSum = sumBy(productKeywordSummaries, k => ~~(k.hasAmazonChoiceBadge ?? false))

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Campaign SEO summary</Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        {campaignDetails.product.store !== Store.walmart && (
          <Metric
            label={'organic search impressions'}
            value={searchVolumeSum ? searchVolumeSum.toLocaleString() : '--'}
          />
        )}
        <Metric
          label={'organic page 1 wins'}
          value={organicPageOneWinsSum ? organicPageOneWinsSum.toLocaleString() : '--'}
        />
        <Metric
          label={'slots moved up in search results'}
          value={organicImprovedSlotsSum ? organicImprovedSlotsSum.toLocaleString() : '--'}
        />
        {choiceBadgesSum >= MIN_AMAZON_CHOICE_BADGES && (
          <Metric label={'Amazon’s Choice badges'} value={choiceBadgesSum ? choiceBadgesSum.toLocaleString() : '--'} />
        )}
      </Stack>
    </Stack>
  )
}
