import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignDetails, CampaignProductReviewRatingMetric } from '@momentum/routes/campaign/context/queries'
import { CampaignContextType } from '@momentum/routes/campaign/context/types'
import { AISummary } from '@momentum/routes/campaign/e-commerce/feedback/feedbackContext'
import { getProductFeedbackSummary } from '@momentum/routes/campaign/e-commerce/feedback/queries'
import { MappedReview, useMappedReviews } from '@momentum/routes/campaign/e-commerce/reviewsV2/useMappedReviews'
import { SeoContextType, useSeoContext } from '@momentum/routes/campaign/e-commerce/seoV2/context'
import { Brand, Company } from '@momentum/routes/queries'
import { getCdnImageUrl } from '@momentum/utils/imageUtils'
import { errorToast } from '@momentum/utils/toastUtils'
import { SaveAltOutlined } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { CampaignStatus, STORE_DETAILS } from '@productwindtom/shared-campaign'
import {
  getCampaignContentCounts,
  getCampaignCreatorCounts,
  getCampaignReviewMetrics,
  getCampaignSeoMetrics,
  getStringForStep,
  TimelineStep
} from '@productwindtom/shared-momentum'
import { ProposalGoal, Store } from '@productwindtom/shared-momentum-zeus-types'
import { Document, Font, Image, Page, pdf, Text, View } from '@react-pdf/renderer'
import { useFlag } from '@unleash/proxy-client-react'
import { first, isNumber, last, max, min, orderBy, sum } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useCampaignTimelineSteps } from '../Timeline/useCampaignTimelineSteps'
import boostIcon from './assets/boost-icon.png'
import campaignSummaryBackdrop from './assets/campaign-summary-backdrop.png'
import checkIcon from './assets/check-icon.png'
import checkOutlinedIcon from './assets/check-outlined-icon.png'
import clickIcon from './assets/click-icon.png'
import slotsJumpedIcon from './assets/slots-jumped-icon.png'
import { styles } from './styles'
import LogoWithText from '/images/logo-with-text.png'
import amazonLogo from '/images/logos/amazon-logo.png'
import bestBuyLogo from '/images/logos/best-buy-logo.png'
import costcoLogo from '/images/logos/costco-logo.png'
import krogerLogo from '/images/logos/kroger-logo.png'
import shopifyLogo from '/images/logos/shopify-logo.png'
import targetLogo from '/images/logos/target-logo.jpg'
import walmartLogo from '/images/logos/walmart-logo.png'

const CampaignSummaryPDF = () => {
  const { brand, company } = useBrandContext()
  const campaignContext = useCampaignContext()
  const seoContext = useSeoContext()
  const timelineSteps = useCampaignTimelineSteps()
  const mappedReviews = useMappedReviews()

  const activeStatus = campaignContext.activeStatus

  const [isLoading, setIsLoading] = useState(false)
  const downloadCampaignSummaryFlag = useFlag('DownloadCampaignSummary')

  if (!downloadCampaignSummaryFlag) return null

  const generatePdf = async () => {
    try {
      setIsLoading(true)
      const feedbackSummary = await getProductFeedbackSummary(campaignContext.campaignDetails.id)
      const aiSummaryResp = feedbackSummary?.jsonDownloadUrl && (await fetch(feedbackSummary.jsonDownloadUrl))
      const aiSummary = aiSummaryResp ? ((await aiSummaryResp.json()) as AISummary) : undefined

      const pdfDocument = (
        <CampaignSummaryPDFBody
          company={company}
          brand={brand}
          timelineSteps={timelineSteps}
          aiSummary={aiSummary}
          campaignContext={campaignContext}
          seoContext={seoContext}
          mappedReviews={mappedReviews}
        />
      )

      await pdf(pdfDocument)
        .toBlob()
        .then(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `Campaign-Summary-${campaignContext.campaignDetails.title}.pdf`.replaceAll(' ', '-')
          a.click()
        })
    } catch (err) {
      console.log('err', err)
      errorToast('Something went wrong, please try again or contact your client success manager.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      disabled={isLoading || activeStatus !== CampaignStatus.FINISHED}
      variant={'text'}
      startIcon={isLoading ? <CircularProgress size={12} color="inherit" /> : <SaveAltOutlined />}
      onClick={generatePdf}
    >
      Download campaign summary
    </Button>
  )
}

export default CampaignSummaryPDF

const CampaignSummaryPDFBody = ({
  company,
  brand,
  timelineSteps,
  aiSummary,
  campaignContext,
  seoContext,
  mappedReviews
}: {
  company: Company
  brand: Brand
  timelineSteps: TimelineStep[]
  aiSummary?: AISummary
  campaignContext: CampaignContextType
  seoContext: SeoContextType
  mappedReviews?: MappedReview[]
}) => {
  Font.register({
    family: 'Avenir',
    fonts: [
      {
        src: 'fonts/Avenir/AvenirRegular.ttf',
        fontWeight: 400
      },
      {
        src: 'fonts/Avenir/AvenirHeavy.ttf',
        fontWeight: 800
      }
    ]
  })

  const { campaignDetails, reviewMetrics = [] } = campaignContext

  const now = DateTime.now().toISODate()
  const startDateDate = DateTime.fromISO(campaignDetails.startDate).toISODate()!
  const endDateDate = campaignDetails.endDate ? DateTime.fromISO(campaignDetails.endDate).toISODate()! : now
  const startOfCampaignData = reviewMetrics.filter(d => d.fromDate >= startDateDate)
  const firstRecord = first(startOfCampaignData)
  const lastRecord = last(startOfCampaignData.filter(d => d.toDate <= endDateDate))

  return (
    <Document
      pageLayout="singlePage"
      pageMode="useNone"
      title={`${campaignDetails.title} Campaign Summary`}
      author="Momentum"
      creator="Momentum"
      creationDate={DateTime.now().toJSDate()}
      language="en"
    >
      <Page
        wrap={false}
        style={{
          fontFamily: 'Avenir'
        }}
      >
        <View>
          <Image
            source={campaignSummaryBackdrop}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
          />

          <View style={[styles.columns, styles.body]}>
            <Header />

            <View style={[styles.row, styles.spacing, { alignItems: 'flex-start' }]}>
              <CampaignHeader campaignDetails={campaignDetails} brand={brand} company={company} />
              <ProductCard campaignDetails={campaignDetails} brand={brand} />
            </View>

            <SummaryBanner campaignDetails={campaignDetails} firstRecord={firstRecord} lastRecord={lastRecord} />

            <Milestones timelineSteps={timelineSteps} />

            <View
              style={[
                styles.row,
                styles.spacing,
                {
                  padding: '0 20px',
                  marginTop: '16px'
                }
              ]}
            >
              <CreatorMetrics campaignDetails={campaignDetails} />
              <DeliverableMetrics
                campaignDetails={campaignDetails}
                campaignContext={campaignContext}
                seoContext={seoContext}
              />
            </View>

            <View
              style={[
                styles.row,
                {
                  gap: '16px'
                }
              ]}
            >
              <TrafficMetrics campaignDetails={campaignDetails} />
              <SeoMetrics campaignDetails={campaignDetails} seoContext={seoContext} />
              <ConversionMetrics
                campaignContext={campaignContext}
                aiSummary={aiSummary}
                mappedReviews={mappedReviews}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

const Header = () => {
  return (
    <View style={[styles.row, styles.header, styles.spacing, styles.spaceBetween]}>
      <Text style={styles.label1}>Campaign Summary</Text>
      <Image
        src={LogoWithText}
        style={{
          width: 130
        }}
      />
    </View>
  )
}

const CampaignHeader = ({
  campaignDetails,
  brand,
  company
}: {
  campaignDetails: CampaignDetails
  brand: Brand
  company: Company
}) => {
  return (
    <View
      style={[
        styles.columns,
        {
          flex: 1.1,
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }
      ]}
    >
      <Text style={[styles.label1, styles.colorPrimary]}>ProductWind x {company.name}</Text>
      <Text
        style={[
          styles.label2,
          {
            textAlign: 'left',
            maxLines: 2,
            textOverflow: 'ellipsis'
          }
        ]}
      >
        {brand.name} | {campaignDetails.title}
      </Text>
      <Text style={[styles.body1]}>
        {DateTime.fromISO(campaignDetails.startDate || campaignDetails.anticipatedStartDate).toLocaleString(
          DateTime.DATE_SHORT
        )}{' '}
        to {DateTime.fromISO(campaignDetails.endDate!).toLocaleString(DateTime.DATE_SHORT)}
      </Text>
    </View>
  )
}

const ProductCard = ({ campaignDetails, brand }: { campaignDetails: CampaignDetails; brand: Brand }) => {
  return (
    <View
      style={[
        styles.row,
        styles.roundBorder,
        {
          flex: 1,
          alignItems: 'center',
          padding: '8px',
          gap: '8px'
        }
      ]}
    >
      <View style={[styles.roundBorder, { width: '60px', height: '60px' }]}>
        <Image
          source={{
            uri: campaignDetails.product.image!,
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
            body: ''
          }}
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%'
          }}
        />
      </View>
      <View style={[styles.columns, { flex: 1, gap: '4px' }]}>
        <View style={[styles.row, { flex: 1, alignItems: 'center' }]}>
          <View
            style={[
              {
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                textAlign: 'center',
                height: '27px'
              }
            ]}
          >
            <Image
              source={{
                uri: getCdnImageUrl(brand.logo)!,
                method: 'GET',
                headers: { 'Cache-Control': 'no-cache' },
                body: ''
              }}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%'
              }}
            />
          </View>
          <View style={[{ flex: 1, justifyContent: 'center', alignItems: 'center', height: '27px' }]}>
            <Image
              source={StoreToIcon[campaignDetails.product.store]}
              style={{
                height: '20px',
                margin: '0 4px'
              }}
            />
          </View>
        </View>
        <View style={[styles.roundBorder, { justifyContent: 'center', alignItems: 'center', height: '27px' }]}>
          <Text style={[styles.caption2]}>
            <Text
              style={[
                styles.colorPrimary,
                {
                  fontWeight: 800
                }
              ]}
            >
              ASIN:
            </Text>{' '}
            {campaignDetails.product.skuId}
          </Text>
        </View>
      </View>
    </View>
  )
}

const SummaryBanner = ({
  campaignDetails,
  firstRecord,
  lastRecord
}: {
  campaignDetails: CampaignDetails
  firstRecord?: CampaignProductReviewRatingMetric
  lastRecord?: CampaignProductReviewRatingMetric
}) => {
  const summaryText = useMemo(() => {
    switch (campaignDetails.goal) {
      case ProposalGoal.PRODUCT_LAUNCH:
        return (
          <>
            <Text style={styles.bold}>{campaignDetails.numCreatorsJoined}</Text> creators drove increased traffic and
            conversions, boosting visibility and improving overall relevancy of the product on{' '}
            <Text style={styles.bold}>{STORE_DETAILS[campaignDetails.product.store].storeLink}</Text>, setting it up for
            long-term success.
          </>
        )

      case ProposalGoal.BOOST_RATING:
        return (
          <>
            Your product’s star rating improved from <Text style={styles.bold}>{firstRecord?.rating}</Text> to{' '}
            <Text style={styles.bold}>{lastRecord?.rating}</Text> stars, with total reviews increasing from{' '}
            <Text style={styles.bold}>{firstRecord?.numReviews}</Text> to{' '}
            <Text style={styles.bold}>{lastRecord?.numReviews}</Text>. Powered by{' '}
            <Text style={styles.bold}>{campaignDetails.numCreatorsJoined}</Text> creators, the campaign drove increased
            traffic and conversions, enhancing the product's overall relevancy on{' '}
            <Text style={styles.bold}>{STORE_DETAILS[campaignDetails.product.store].storeLink}</Text>.
          </>
        )
      default:
        return (
          <>
            <Text style={styles.bold}>{campaignDetails.numCreatorsJoined}</Text> creators drove increased traffic and
            conversions, boosting visibility and improving overall relevancy of the product on{' '}
            <Text style={styles.bold}>{STORE_DETAILS[campaignDetails.product.store].storeLink}</Text>.
          </>
        )
    }
  }, [campaignDetails, firstRecord, lastRecord])

  return (
    <View
      style={[
        styles.row,
        styles.roundBorder,
        styles.spacing,
        {
          padding: '4px 8px',
          alignItems: 'center'
        }
      ]}
    >
      <Image
        source={clickIcon}
        style={{
          width: '40px',
          height: '40px',
          marginRight: '8px'
        }}
      />
      <Text
        style={[
          styles.body1,
          {
            lineHeight: '1.2',
            flex: 1
          }
        ]}
      >
        {summaryText}
      </Text>
    </View>
  )
}

const Milestones = ({ timelineSteps }: { timelineSteps: TimelineStep[] }) => {
  return (
    <View
      style={[
        styles.columns,
        styles.spacing,
        {
          paddingVertical: '8px'
        }
      ]}
    >
      <Text
        style={[
          styles.label2,
          styles.spacing,
          {
            textAlign: 'center'
          }
        ]}
      >
        Campaign milestones
      </Text>
      <View
        style={[
          styles.row,
          styles.spacing,
          {
            justifyContent: 'space-between',
            padding: '0 12px'
          }
        ]}
      >
        {timelineSteps.map((step, index) => {
          return (
            <View
              key={index}
              style={[
                {
                  flex: 1,
                  textAlign: 'center',
                  flexDirection: index % 2 === 0 ? 'column-reverse' : 'column'
                }
              ]}
            >
              {/* Spacer */}
              <View
                style={[
                  {
                    height: timelineSteps.length >= 7 ? '60px' : '45px'
                  }
                ]}
              />

              {/* Timeline */}
              <View
                style={[
                  {
                    position: 'relative'
                  }
                ]}
              >
                {index !== 0 && (
                  <View
                    style={[
                      //
                      styles.timelineLine,
                      {
                        left: '-20px'
                      }
                    ]}
                  />
                )}
                <Text style={[styles.timelineStepCircle]}>{index + 1}</Text>
                {index !== timelineSteps.length - 1 && (
                  <View
                    style={[
                      //
                      styles.timelineLine,
                      {
                        right: '-20px'
                      }
                    ]}
                  />
                )}
              </View>

              {/* Labels */}
              <View
                style={[
                  {
                    height: timelineSteps.length >= 7 ? '60px' : '45px',
                    position: 'relative'
                  }
                ]}
              >
                {/* Labels */}
                <View
                  style={[
                    {
                      height: timelineSteps.length >= 7 ? '60px' : '45px',
                      flexDirection: index % 2 === 0 ? 'column-reverse' : 'column',
                      alignItems:
                        index === 0 ? 'flex-start' : index === timelineSteps.length - 1 ? 'flex-end' : 'center',
                      justifyContent: 'space-between',
                      width: '86px',
                      position: 'absolute',
                      left: '50%',
                      transform:
                        index === 0
                          ? `translate(-12px)` // half the circle size
                          : index === timelineSteps.length - 1
                            ? `translate(-71px)` // full label size + half the circle size
                            : `translate(-43px)`, // half the label size
                      gap: '8px'
                    }
                  ]}
                >
                  <Text
                    style={[
                      styles.caption1,
                      {
                        fontSize: '9px',
                        textAlign: index === 0 ? 'left' : index === timelineSteps.length - 1 ? 'right' : 'center'
                      }
                    ]}
                  >
                    {getStringForStep(step)}
                  </Text>
                  <Text
                    style={[
                      styles.caption2,
                      {
                        fontSize: '9px'
                      }
                    ]}
                  >
                    {step?.date?.toLocaleString(DateTime.DATE_MED)}
                  </Text>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}

const CreatorMetrics = ({ campaignDetails }: { campaignDetails: CampaignDetails }) => {
  const { brandAdvocatesTotal, numSocialCreators, numUgcCreators, numPremiumUgcCreators } =
    getCampaignCreatorCounts(campaignDetails)

  const totalMetricsItems =
    ~~!!brandAdvocatesTotal + ~~!!numUgcCreators + ~~!!numPremiumUgcCreators + ~~!!numSocialCreators

  return (
    <View
      style={[
        {
          height: '100%'
        }
      ]}
    >
      <Text
        style={[
          styles.label2,
          {
            textAlign: 'center',
            marginBottom: '16px'
          }
        ]}
      >
        Participating creators
      </Text>
      <View
        style={[
          styles.row,
          {
            flexWrap: 'wrap',
            width: '235px',
            gap: '8px'
          }
        ]}
      >
        {!!brandAdvocatesTotal && (
          <CreatorMetricsItem value={brandAdvocatesTotal} text="brand advocates" totalItems={totalMetricsItems} />
        )}
        {!!numUgcCreators && (
          <CreatorMetricsItem value={numUgcCreators} text="UGC creators" totalItems={totalMetricsItems} />
        )}
        {!!numPremiumUgcCreators && (
          <CreatorMetricsItem
            value={numPremiumUgcCreators}
            text={`premium UGC\ncreators`}
            totalItems={totalMetricsItems}
          />
        )}
        {!!numSocialCreators && (
          <CreatorMetricsItem value={numSocialCreators} text="social creators" totalItems={totalMetricsItems} />
        )}
      </View>
    </View>
  )
}

const CreatorMetricsItem = ({ value, text, totalItems }: { value: number; text: string; totalItems: number }) => {
  const style =
    totalItems === 1
      ? {
          width: '96%',
          height: '196px'
        }
      : totalItems === 2
        ? {
            width: '96%',
            height: '94px'
          }
        : {
            width: '48%',
            height: '94px'
          }

  return (
    <View
      style={[
        styles.columns,
        styles.roundBorder,
        {
          alignItems: 'center',
          justifyContent: 'center',
          ...style
        }
      ]}
    >
      <Text
        style={[
          styles.label2,
          {
            textAlign: 'center'
          }
        ]}
      >
        {value.toLocaleString()}
      </Text>
      <Text
        style={[
          styles.body1,
          {
            textAlign: 'center'
          }
        ]}
      >
        {text}
      </Text>
    </View>
  )
}

const DeliverableMetrics = ({
  campaignDetails,
  campaignContext,
  seoContext
}: {
  campaignDetails: CampaignDetails
  campaignContext: CampaignContextType
  seoContext: SeoContextType
}) => {
  const {
    expectedSocialPostCount,
    expectedReviewsCount,
    expectedPremiumUgcPostCount,
    expectedUgcPostCount,
    expectedPlacementWins
  } = campaignDetails

  const { reviewMetrics = [] } = campaignContext

  const { momentumReviews } = getCampaignReviewMetrics(reviewMetrics)

  const { socialContentCount, premiumUgcContentCount, ugcContentCount } = getCampaignContentCounts(campaignDetails)

  const { productSeoSummary } = seoContext
  const pageOneWins = sum((productSeoSummary?.productSeoSummaryRecords || []).map(p => p?.organicPageOneWins ?? 0))

  return (
    <View style={[styles.columns, { flex: 1, alignContent: 'center', alignItems: 'center', gap: '8px' }]}>
      <Text
        style={[
          styles.label2,
          {
            textAlign: 'center',
            marginBottom: '8px'
          }
        ]}
      >
        Campaign deliverables
      </Text>
      {!!expectedReviewsCount && (
        <DeliverableMetricsItem actual={momentumReviews} expected={expectedReviewsCount} title="reviews & ratings" />
      )}

      {!!expectedPlacementWins && (
        <DeliverableMetricsItem actual={pageOneWins} expected={expectedPlacementWins} title="organic placement wins" />
      )}

      {!!expectedSocialPostCount && (
        <DeliverableMetricsItem
          expected={expectedSocialPostCount}
          actual={socialContentCount}
          title="social posts & stories"
        />
      )}

      {!!expectedPremiumUgcPostCount && (
        <DeliverableMetricsItem
          expected={expectedPremiumUgcPostCount}
          actual={premiumUgcContentCount}
          title="premium UGC"
        />
      )}
      {!!expectedUgcPostCount && (
        <DeliverableMetricsItem expected={expectedUgcPostCount} actual={ugcContentCount} title="authentic UGC" />
      )}
    </View>
  )
}

const DeliverableMetricsItem = ({ expected, actual, title }: { expected: number; actual: number; title: string }) => {
  return (
    <View
      style={[
        styles.columns,
        styles.roundBorder,
        {
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '60px',
          padding: '8px'
        }
      ]}
    >
      <View
        style={[
          styles.row,
          { gap: '4px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', width: '100%' }
        ]}
      >
        <Image
          source={checkIcon}
          style={{
            width: '18px',
            height: '18px'
          }}
        />
        <Text style={styles.label2}>{getMetricOutOfString(actual, expected)}</Text>
      </View>
      <Text style={styles.body1}>{title}</Text>
    </View>
  )
}

const TrafficMetrics = ({ campaignDetails }: { campaignDetails: CampaignDetails }) => {
  const { socialContentCount, premiumUgcContentCount, ugcContentCount } = getCampaignContentCounts(campaignDetails)

  const totalUgcContent = premiumUgcContentCount + ugcContentCount

  return (
    <MetricsBlock title="Traffic">
      {!!totalUgcContent && <MetricItem title="pieces of UGC" value={totalUgcContent} />}
      {!!socialContentCount && <MetricItem title="social posts" value={socialContentCount} />}
    </MetricsBlock>
  )
}

const SeoMetrics = ({
  campaignDetails,
  seoContext
}: {
  campaignDetails: CampaignDetails
  seoContext: SeoContextType
}) => {
  const { productSeoSummary, productKeywordSummaries } = seoContext
  const {
    searchVolumeSum,
    organicPageOneWinsSum,
    organicImprovedSlotsSum,
    sponsoredPageOneWinsSum,
    sponsoredImprovedSlotsSum
  } = getCampaignSeoMetrics(campaignDetails.startDate, productSeoSummary, productKeywordSummaries)

  if (!searchVolumeSum) {
    return null
  }

  return (
    <MetricsBlock title="SEO">
      {campaignDetails.product.store !== Store.walmart && (
        <MetricItem title="organic search impressions" value={searchVolumeSum} />
      )}
      {!!organicPageOneWinsSum && (
        <MetricItem title="organic Page 1 wins" value={organicPageOneWinsSum} icon={checkOutlinedIcon} />
      )}
      {!!organicImprovedSlotsSum && (
        <MetricItem title="organic slots jumped" value={organicImprovedSlotsSum} icon={slotsJumpedIcon} />
      )}
      {!!sponsoredPageOneWinsSum && (
        <MetricItem title="sponsored Page 1 wins" value={sponsoredPageOneWinsSum} icon={checkOutlinedIcon} />
      )}
      {!!sponsoredImprovedSlotsSum && (
        <MetricItem title="sponsored slots jumped" value={sponsoredImprovedSlotsSum} icon={slotsJumpedIcon} />
      )}
    </MetricsBlock>
  )
}

const ConversionMetrics = ({
  campaignContext,
  aiSummary,
  mappedReviews
}: {
  campaignContext: CampaignContextType
  aiSummary?: AISummary
  mappedReviews?: MappedReview[]
}) => {
  const { campaignDetails, reviewMetrics } = campaignContext
  const { startDate, endDate } = campaignDetails

  const momentumReviewRecordsDates =
    reviewMetrics?.filter(r => r.momentumReviewCount > 0).map(m => DateTime.fromISO(m.fromDate)) || []

  const startDateFilter = min([DateTime.now(), DateTime.fromISO(startDate)])!
  const endDateFilter = max([...momentumReviewRecordsDates, endDate ? DateTime.fromISO(endDate) : DateTime.now()])!

  const filteredReviewData = orderBy(
    mappedReviews?.filter(d => d.date >= startDateFilter.toISODate()! && d.date <= endDateFilter.toISODate()!),
    'date'
  )

  const summaryRatingChange = (last(filteredReviewData)?.rating || 0) - (first(filteredReviewData)?.rating || 0)
  const summaryTotalRatings =
    (last(filteredReviewData)?.cumulativeRatings || 0) - (first(filteredReviewData)?.cumulativeRatings || 0)
  const summaryTotalAverageRatings =
    (last(filteredReviewData)?.cumulativeAverageRatings || 0) -
    (first(filteredReviewData)?.cumulativeAverageRatings || 0)

  const feedbackEntries = aiSummary?.feedback.filter(f => f.sentiment !== 'neutral').length || 0

  const reviewsIncrease = summaryTotalRatings - summaryTotalAverageRatings

  return (
    <MetricsBlock title="Conversions">
      {!!summaryTotalRatings && <MetricItem title="total reviews & ratings" value={summaryTotalRatings} />}
      {!!reviewsIncrease && (
        <MetricItem title="total increase in reviews & ratings" value={reviewsIncrease} icon={boostIcon} />
      )}
      {summaryRatingChange > 0 && (
        <MetricItem title="boost in star rating" value={summaryRatingChange.toFixed(2)} icon={boostIcon} />
      )}
      {!!feedbackEntries && <MetricItem title="total product feedback from creators" value={feedbackEntries} />}
    </MetricsBlock>
  )
}

const MetricsBlock = ({ title, children }: { title: string; children: React.ReactNode }) => {
  if (React.Children.toArray(children).filter(Boolean).length === 0) {
    return null
  }

  return (
    <View
      style={[
        styles.columns,
        {
          alignContent: 'center',
          alignItems: 'center',
          flex: 1
        }
      ]}
    >
      <Text
        style={[
          styles.label2,
          {
            textAlign: 'center',
            marginBottom: '8px'
          }
        ]}
      >
        {title}
      </Text>
      <View
        style={[
          styles.roundBorder,
          {
            padding: '8px 16px',
            gap: '12px',
            width: '100%',
            minHeight: '250px'
          }
        ]}
      >
        {children}
      </View>
    </View>
  )
}

const MetricItem = ({ value, title, icon }: { value: number | string; title: string; icon?: string }) => {
  if (!value) return null

  return (
    <View style={[styles.columns, {}]}>
      <View
        style={[
          styles.row,
          {
            alignItems: 'center',
            gap: '4px'
          }
        ]}
      >
        {icon && <Image source={icon} style={{ width: '15px', height: '15px' }} />}
        <Text style={styles.label2}>{isNumber(value) ? value.toLocaleString() : value} </Text>
      </View>
      <Text style={[styles.body1, {}]}>{title}</Text>
    </View>
  )
}

const getMetricOutOfString = (joined: number = 0, total?: number) => {
  if (!total) {
    return '--'
  }

  return `${joined.toLocaleString()} of ${total.toLocaleString()}`
}

export const StoreToIcon: Record<Store, string> = {
  [Store.amazon]: amazonLogo,
  [Store.amazon_ca]: amazonLogo,
  [Store.amazon_uk]: amazonLogo,
  [Store.amazon_de]: amazonLogo,
  [Store.bestBuy]: bestBuyLogo,
  [Store.walmart]: walmartLogo,
  [Store.target]: targetLogo,
  [Store.costco]: costcoLogo,
  [Store.shopify]: shopifyLogo,
  [Store.dtc]: shopifyLogo,
  [Store.kroger]: krogerLogo,
  [Store.other]: shopifyLogo
}
