import Package from '@momentum/components/icons/package'
import SaleIcon from '@momentum/components/icons/sale'
import {
  PublishedWithChangesOutlined,
  RateReviewOutlined,
  RocketLaunchOutlined,
  ShoppingCartOutlined,
  StarBorder
} from '@mui/icons-material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'
import { ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'

export const CampaignGoalTypeToIcon: Record<ProposalGoal, (props?: SvgIconProps) => JSX.Element> = {
  PRODUCT_LAUNCH: (props?: SvgIconProps) => <RocketLaunchOutlined {...props} />,
  BOOST_RATING: (props?: SvgIconProps) => <StarBorder {...props} />,
  EXISTING_PRODUCT: (props?: SvgIconProps) => <Package {...props} />,
  EVERGREEN: (props?: SvgIconProps) => <PublishedWithChangesOutlined {...props} />,
  EVENT: (props?: SvgIconProps) => <SaleIcon {...props} />,
  MARKET_RESEARCH: (props?: SvgIconProps) => <RateReviewOutlined {...props} />,
  OTHER: (props?: SvgIconProps) => <ShoppingCartOutlined {...props} />
}
