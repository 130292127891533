import { HubCapsule } from '@aws-amplify/core'
import { ToastProvider } from '@momentum/ToastProvider'
import { enableInterceptedApi, mApi } from '@momentum/api'
import { SAMPLE_COMPANY } from '@momentum/api/interceptors/getCompany/sample-data'
import ErrorState from '@momentum/components/error-state'
import Loading from '@momentum/components/loading'
import { NavDrawer, drawerWidth } from '@momentum/components/nav-drawer'
import { SubscriptionProvider } from '@momentum/contexts/Subscription'
import UserSessionContext, { CountsByBrands } from '@momentum/contexts/UserSession'
import { useIdleHandler } from '@momentum/hooks/useIdleHandler'
import { AppBar } from '@momentum/routes/AppBar'
import {
  BASE_ROUTES,
  ROUTES,
  ROUTE_NAMES_BRAND,
  ROUTE_NAMES_CAMPAIGN,
  ROUTE_NAMES_CAMPAIGN_ECOMMERCE,
  ROUTE_NAMES_CAMPAIGN_MY_ACTIONS,
  ROUTE_NAMES_CAMPAIGN_OVERVIEW,
  ROUTE_NAMES_PRODUCT,
  ROUTE_NAMES_PROPOSAL
} from '@momentum/routes/RouteNames'
import ForgotPassword from '@momentum/routes/account/forgot-password'
import ForgotPasswordSetPassword from '@momentum/routes/account/forgot-password-set-password'
import SignUp from '@momentum/routes/account/signup'
import { AllSubscriptions } from '@momentum/routes/all-subscriptions'
import { BrandCampaigns } from '@momentum/routes/brand/campaigns'
import { BrandProposals } from '@momentum/routes/brand/proposals'
import { BrandRecommendations } from '@momentum/routes/brand/recommendations'
import { Campaign } from '@momentum/routes/campaign'
import Performance from '@momentum/routes/campaign/e-commerce/performance'
import { Traffic } from '@momentum/routes/campaign/e-commerce/traffic'
import { CampaignOverview } from '@momentum/routes/campaign/overview'
import { OverviewProposal } from '@momentum/routes/campaign/overview/proposal'
import { Summary } from '@momentum/routes/campaign/overview/summary'
import { ProductAlerts } from '@momentum/routes/products/alerts'
import { ViewAllProducts } from '@momentum/routes/products/view-all'
import { ProposalCreateWithLoading } from '@momentum/routes/proposals-create'
import { CreateWin } from '@momentum/routes/proposals-create/create-win'
import { ProductCreators } from '@momentum/routes/proposals-create/product-creators'
import {
  Agency,
  BaseBrand,
  Brand,
  Company,
  User,
  getAgency,
  getBrand,
  getCompany,
  getUserResources,
  userSelector,
  BaseCompany
} from '@momentum/routes/queries'
import { Subscription } from '@momentum/routes/subscription'
import { Box, Container, ContainerProps } from '@mui/material'
import { AccessLevel } from '@productwindtom/shared-momentum-zeus-types'
import * as Sentry from '@sentry/react'
import { useFlag, useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import { first, keyBy, map, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import mixpanel from 'mixpanel-browser'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  generatePath,
  matchPath,
  useLocation,
  useMatch,
  useNavigate,
  useParams
} from 'react-router-dom'
import { Join } from './account/join'
import Login from './account/login'
import { AgencyProfile } from './agency/profile'
import { Brand as BrandScreen } from './brand'
import { BrandProfile } from './brand-profile'
import BrandContent from './brand/content'
import BrandProvider from './brand/context/BrandContext'
import { Brands } from './brands'
import { CampaignContent } from './campaign/content'
import { CampaignECommerce } from './campaign/e-commerce'
import Benchmark from './campaign/e-commerce/benchmark'
import { Feedback } from './campaign/e-commerce/feedback'
import { Reviews } from './campaign/e-commerce/reviews'
import { Sales } from './campaign/e-commerce/sales'
import { OrganicSeo } from './campaign/e-commerce/seoV2/organic'
import { SponsoredSeo } from './campaign/e-commerce/seoV2/sponsored'
import { CampaignMyActions } from './campaign/my-actions'
import ApproveContent from './campaign/my-actions/approve-content'
import { ApproveCreators } from './campaign/my-actions/approve-creators'
import { LinkAccount } from './campaign/my-actions/link-account'
import { IntegrationType } from './campaign/my-actions/link-account/utils'
import { Company as CompanyScreen } from './company'
import { Products } from './products'
import { Proposals } from './proposals'
import { Review } from './proposals-create/review'
import { Timeline } from './proposals-create/timeline'
import Recommendations from './recommendations'
import { Team } from './team'
import { Users } from './users'
import Support from '../sections/Support'
import { ReviewsV2 } from '@momentum/routes/campaign/e-commerce/reviewsV2'
import { useLayoutContext } from '@momentum/contexts/LayoutContext'

const UnAuthRouteElement = ({ children, authUser }: { authUser?: CognitoUser; children: React.ReactNode }) => {
  return !authUser ? children : <Navigate to={ROUTES.HOME} replace />
}

export default function Navigation() {
  const [loaded, setLoaded] = useState(false)
  const [authUser, setAuthUser] = useState<CognitoUser | undefined>()
  const [user, setUser] = useState<User | undefined>()
  const [agencyId, setAgencyId] = useState<string | undefined>()
  const unleashClient = useUnleashClient()
  const updateContext = useUnleashContext()
  useIdleHandler(user)

  useEffect(() => {
    if (user) {
      const toggles = unleashClient.getAllToggles().reduce((acc, t) => ({ ...acc, [t.name]: t.enabled }), {})

      Sentry.setUser({
        id: user.id,
        username: user.email,
        email: user.email,
        attributes: {
          firstName: user.firstName,
          lastName: user.lastName,
          toggles
        }
      })
      mixpanel.identify(user.id)
      mixpanel.people.set({
        $name: `${user.firstName} ${user.lastName}`,
        $email: user.email,
        role: user.role,
        toggles
      })
    } else {
      Sentry.setUser(null)
      mixpanel.identify()
      updateContext({})
    }
  }, [user])

  const authListener = (data: HubCapsule) => {
    switch (data.payload.event) {
      case 'autoSignIn':
        if (!data.payload.data?.challengeName) {
          setAuthUser(data.payload.data)
        }
        break
      case 'signIn':
        setAuthUser(data.payload.data)
        break
      case 'signUp':
        break
      case 'autoSignIn_failure':
      case 'signIn_failure':
      case 'signOut':
        setAuthUser(undefined)
        setUser(undefined)
        break
      case 'tokenRefresh':
        break
      case 'tokenRefresh_failure':
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (authUser) {
      mApi('query')({
        getUser: [{ id: authUser.getUsername() }, userSelector],
        listUserAgencies: [{ userId: authUser.getUsername() }, { items: { agencyId: true } }]
      })
        .then(({ getUser, listUserAgencies }) => {
          const agencyId = first(listUserAgencies?.items)?.agencyId
          const isAdmin = authUser
            .getSignInUserSession()
            ?.getAccessToken()
            ?.payload['cognito:groups']?.includes('admin')

          setUser(getUser)

          if (agencyId) {
            setAgencyId(agencyId)
            mixpanel.people.set({ agencyId })
            if (!window.location.href.includes(agencyId)) {
              window.location.href = `${window.location.origin}${generatePath(ROUTES.AGENCY, { agencyId })}${window.location.pathname}`
            }
          } else if (isAdmin) {
            const pathMatch = matchPath({ path: BASE_ROUTES.AGENCY, end: false }, window.location.pathname)
            if (pathMatch?.params?.agencyId) {
              setAgencyId(pathMatch?.params?.agencyId)
            }
          }

          if (getUser) {
            return mApi('mutation')({
              updateUser: [
                {
                  input: {
                    id: getUser.id,
                    lastActiveAt: DateTime.utc().toISO()
                  }
                },
                { id: true, lastActiveAt: true }
              ]
            })
          }
        })
        .finally(() => setLoaded(true))
    }
  }, [authUser])

  useEffect(() => {
    if (agencyId && !authUser) {
      setAgencyId(undefined)
    }
  }, [agencyId, authUser])

  useEffect(() => {
    Hub.listen('auth', authListener)
    Auth.currentAuthenticatedUser()
      .then(setAuthUser)
      .catch(() => setLoaded(true))
  }, [])

  if (!loaded) {
    return <div />
  }

  const routes = [
    {
      path: ROUTES.LOGIN,
      element: (
        <UnAuthRouteElement authUser={authUser}>
          <Login />
        </UnAuthRouteElement>
      )
    },
    {
      path: ROUTES.SIGN_UP_VIA_INVITATION,
      element: (
        <UnAuthRouteElement authUser={authUser}>
          <SignUp />
        </UnAuthRouteElement>
      )
    },

    {
      path: ROUTES.FORGOT_PASSWORD,
      element: (
        <UnAuthRouteElement authUser={authUser}>
          <ForgotPassword />
        </UnAuthRouteElement>
      )
    },
    {
      path: ROUTES.FORGOT_PASSWORD_SET_PASSWORD,
      element: (
        <UnAuthRouteElement authUser={authUser}>
          <ForgotPasswordSetPassword />
        </UnAuthRouteElement>
      )
    },
    {
      path: ROUTES.JOIN,
      element: (
        <UnAuthRouteElement authUser={authUser}>
          <Join />
        </UnAuthRouteElement>
      )
    },
    ...(authUser && user
      ? [
          {
            path: '*',
            element: (
              <AuthenticatedUserNavigation
                authUser={authUser}
                user={user}
                agencyId={agencyId}
                setAgencyId={setAgencyId}
              />
            )
          }
        ]
      : []),
    {
      path: '/*',
      element: <Navigate to={authUser ? ROUTES.HOME : ROUTES.LOGIN} />
    }
  ]

  const routesWithToast = [
    {
      element: (
        <ErrorState>
          <ToastProvider />
        </ErrorState>
      ),
      children: routes
    }
  ]

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

  const router = sentryCreateBrowserRouter(routesWithToast, {
    basename: agencyId ? generatePath(ROUTES.AGENCY, { agencyId }) : '/'
  })

  return (
    <Container maxWidth={false} sx={{ height: '100%' }} disableGutters>
      <RouterProvider router={router} />
    </Container>
  )
}

const AuthenticatedUserNavigation = ({
  authUser,
  user,
  agencyId,
  setAgencyId
}: {
  authUser: CognitoUser
  user: User
  agencyId?: string
  setAgencyId: (agencyId?: string) => void
}) => {
  const isAdmin = authUser.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups']?.includes('admin')
  const [loading, setLoading] = useState(true)

  const isAgency = !!agencyId
  const isAdminOrAgency = isAdmin || isAgency

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProviderOutlet
            authUser={authUser}
            user={user}
            agencyId={agencyId}
            setAgencyId={setAgencyId}
            loading={loading}
            setLoading={setLoading}
            isAdmin={isAdmin}
          />
        }
      >
        <Route
          element={useRouteWithNav(routeWithLoading(<BrandProvider />, loading), { excludeAppBar: true })}
          path={ROUTES.BRANDS}
        >
          <Route element={routeWithAppBar(<BrandScreen />)} path={ROUTES.BRAND}>
            <Route path={ROUTE_NAMES_BRAND.PROPOSALS} element={<BrandProposals />} />
            <Route path={ROUTE_NAMES_BRAND.RECOMMENDATIONS} element={<BrandRecommendations />} />
            <Route path={ROUTE_NAMES_BRAND.CAMPAIGNS} element={<BrandCampaigns />} />
          </Route>
          <Route path={ROUTES.BRAND_PRODUCTS} element={routeWithAppBar(<Products />)}>
            <Route path={ROUTE_NAMES_PRODUCT.VIEW} element={<ViewAllProducts />} />
            <Route path={ROUTE_NAMES_PRODUCT.ALERTS} element={<ProductAlerts />} />
          </Route>
          <Route path={ROUTES.BRAND_CONTENT} element={routeWithAppBar(<BrandContent />)} />
          <Route element={<ProposalCreateWithLoading />} path={ROUTES.BRAND_CREATE_PROPOSAL}>
            {proposalSet}
          </Route>
          <Route element={<Navigate to="edit" replace={true} />} path={ROUTES.BRAND_PROPOSAL} />
          <Route element={<ProposalCreateWithLoading />} path={ROUTES.BRAND_CREATE_PROPOSAL_EDIT}>
            {proposalSet}
          </Route>
          <Route element={<ProposalCreateWithLoading />} path={ROUTES.BRAND_CREATE_PROPOSAL_FROM_RECOMMENDATION}>
            {proposalSet}
          </Route>
          <Route
            element={routeWithAppBar(<Campaign />, { containerProps: { maxWidth: false, disableGutters: true } })}
            path={ROUTES.CAMPAIGN}
          >
            <Route path={ROUTE_NAMES_CAMPAIGN.CONTENT} element={<CampaignContent />} />
            <Route path={ROUTE_NAMES_CAMPAIGN.ECOMMERCE} element={<CampaignECommerce />}>
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.TRAFFIC}
                element={
                  <Box data-cy={'ecommerceTraffic'}>
                    <Traffic />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SEO_V2}
                element={
                  <Box data-cy={'ecommerceSeoV2'}>
                    <OrganicSeo />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SPONSORED_RANK}
                element={
                  <Box data-cy={'ecommerceSponsoredRank'}>
                    <SponsoredSeo />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS}
                element={
                  <Box data-cy={'ecommerceReviews'}>
                    <Reviews />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.REVIEWS_V2}
                element={
                  <Box data-cy={'ecommerceReviewsV2'}>
                    <ReviewsV2 />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.SALES}
                element={
                  <Box data-cy={'ecommerceSales'}>
                    <Sales />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.FEEDBACK}
                element={
                  <Box data-cy={'ecommerceFeedback'}>
                    <Feedback />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.BENCHMARK}
                element={
                  <Box data-cy={'ecommerceBenchmark'}>
                    <Benchmark />
                  </Box>
                }
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_ECOMMERCE.PERFORMANCE}
                element={
                  <Box data-cy={'ecommercePerformance'}>
                    <Performance />
                  </Box>
                }
              />
            </Route>
            <Route path={ROUTE_NAMES_CAMPAIGN.MY_ACTIONS} element={<CampaignMyActions />}>
              <Route path={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CREATORS} element={<ApproveCreators />} />
              <Route path={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.APPROVE_CONTENT} element={<ApproveContent />} />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_SELLER}
                element={<LinkAccount type={IntegrationType.SELLER} />}
              />
              <Route
                path={ROUTE_NAMES_CAMPAIGN_MY_ACTIONS.LINK_VENDOR}
                element={<LinkAccount type={IntegrationType.VENDOR} />}
              />
            </Route>
            <Route path={ROUTE_NAMES_CAMPAIGN.OVERVIEW} element={<CampaignOverview />}>
              <Route path={ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY} element={<Summary />} />
              <Route path={ROUTE_NAMES_CAMPAIGN_OVERVIEW.PROPOSAL} element={<OverviewProposal />} />
            </Route>
          </Route>
        </Route>
        <Route element={useRouteWithNavLoading(<BrandProfile />, loading)} path={ROUTES.BRAND_PROFILE} />

        <Route
          element={adminManagementRoute(useRouteWithNavLoading(<Brands />, loading), isAdminOrAgency)}
          path={isAgency ? ROUTES.HOME : ROUTES.ADMIN_BASE}
        />

        <Route
          element={adminManagementRoute(useRouteWithNav(routeWithLoading(<CompanyScreen />, loading)), isAdminOrAgency)}
          path={ROUTES.AGENCY_COMPANY}
        />
        <Route
          element={adminManagementRoute(useRouteWithNav(routeWithLoading(<CompanyScreen />, loading)), isAdminOrAgency)}
          path={ROUTES.COMPANY}
        />
        <Route
          element={adminManagementRoute(useRouteWithNavLoading(<Team />, loading), isAdminOrAgency)}
          path={isAdmin ? ROUTES.AGENCY_TEAM : ROUTES.TEAM_BASE}
        />
        <Route
          element={adminManagementRoute(useRouteWithNavLoading(<AgencyProfile />, loading), isAdmin)}
          path={ROUTES.AGENCY_PROFILE}
        />

        <Route element={useRouteWithNavLoading(<Products />, loading)} path={ROUTES.PRODUCTS} />
        <Route element={useRouteWithNavLoading(<Proposals />, loading)} path={ROUTES.ADMIN_PROPOSALS} />
        <Route element={useRouteWithNavLoading(<Recommendations />, loading)} path={ROUTES.ADMIN_RECOMMENDATIONS} />
        <Route element={useRouteWithNavLoading(<Users />, loading)} path={ROUTES.ADMIN_USERS} />
        <Route element={useRouteWithNavLoading(<AllSubscriptions />, loading)} path={ROUTES.ADMIN_SUBSCRIPTIONS} />
        <Route element={useRouteWithNavLoading(<Team />, loading)} path={ROUTES.COMPANY_TEAM} />
        <Route element={useRouteWithNavLoading(<Subscription />, loading)} path={ROUTES.BRAND_SUBSCRIPTION} />
        <Route
          element={
            isAdmin ? (
              <Navigate to={ROUTES.ADMIN_BASE} replace />
            ) : agencyId ? (
              <Navigate to={ROUTES.HOME} replace />
            ) : (
              routeWithLoading(<Navigate to={ROUTES.BRANDS} replace />, loading)
            )
          }
          path={ROUTES.HOME}
        />
        <Route element={<Navigate to={ROUTES.HOME} replace />} path={'*'} />
      </Route>
    </Routes>
  )
}

const ProviderOutlet = ({
  authUser,
  user,
  agencyId: userAgencyId,
  setAgencyId: setUserAgencyId,
  loading,
  setLoading,
  isAdmin
}: {
  authUser: CognitoUser
  user: User
  agencyId?: string
  setAgencyId: (agencyId?: string) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  isAdmin: boolean
}) => {
  const { brandId, companyId } = useParams<{ brandId?: string; companyId?: string; agencyId?: string }>()
  const [isAdminView, setIsAdminView] = useState(isAdmin)
  const [brands, setBrands] = useState<BaseBrand[]>([])
  const [companies, setCompanies] = useState<BaseCompany[]>([])
  const [agencies, setAgencies] = useState<Agency[]>([])
  const [agency, setAgency] = useState<Agency>()
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>()
  const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>()

  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [selectedBrand, setSelectedBrand] = useState<Brand>()

  const [countsByBrand, setCountByBrand] = useState<CountsByBrands>()
  const [loadingResources, setLoadingResources] = useState<boolean>(false)

  const isBrandMatch = !!useMatch(ROUTES.BRAND + '/*')
  const isCompanyBaseMatch = !!useMatch(ROUTES.COMPANY)
  const isCompanyMatch = !!useMatch(ROUTES.COMPANY + '/*')

  const isAdminManagementView = useMemo(() => {
    return isCompanyBaseMatch || (!isBrandMatch && !isCompanyMatch)
  }, [isBrandMatch, isCompanyMatch, isCompanyBaseMatch])

  const isViewOnly = user.accessLevel === AccessLevel.VIEW_ONLY && !isAdminView

  const navigate = useNavigate()
  const location = useLocation()
  const updateContext = useUnleashContext()
  const includeSampleCompaniesFlag = useFlag('SampleCompanies')

  const agencyId = userAgencyId
  const includeSamples = (includeSampleCompaniesFlag && !isAdmin) || (isAdmin && !!agencyId)
  const sampleBrands = SAMPLE_COMPANY.brands.map(b => ({
    ...b,
    companyId: agencyId ? b.companyId : first(companies)?.id
  }))

  const companiesWithSamples = includeSamples
    ? uniqBy([...companies, SAMPLE_COMPANY], 'id').map(c =>
        c.id === SAMPLE_COMPANY.id ? { ...SAMPLE_COMPANY, agencyId: agencyId || SAMPLE_COMPANY.agencyId } : c
      )
    : companies

  const brandsWithSamples = includeSamples ? uniqBy([...brands, ...sampleBrands], 'id') : brands
  const countsByBrandWithSamples = includeSamples
    ? {
        ...countsByBrand,
        ...keyBy(
          sampleBrands.map(b => ({
            brandId: b.id,
            totalProducts: b.products.items.length,
            totalRecommendations: b.products.items.filter(p => p.recommendations.length).length
          }))
        )
      }
    : countsByBrand

  useEffect(() => {
    getUserResources(user.id, isAdmin, !!userAgencyId)
      .then(({ brands, companies, agencies, countsByBrand }) => {
        setCompanies(uniqBy(companies, 'id'))
        setBrands(uniqBy(brands, 'id'))
        setCountByBrand(countsByBrand)
        setAgencies(agencies || [])
      })
      .finally(() => setLoading(false))
  }, [userAgencyId, isAdmin, setLoading, user.id])

  useEffect(() => {
    if (agencyId) {
      getAgency(agencyId).then(setAgency)
    }
  }, [agencies, agencyId])

  useEffect(() => {
    const brand = brandsWithSamples.find(b => b.id === brandId)
    const company = companyId && !!companiesWithSamples.find(company => company.id === companyId)

    if (brand && brand?.id !== selectedBrand?.id) {
      setSelectedBrandId(brand.id)
      setSelectedCompanyId(brand?.companyId || companyId)
    } else if (company) {
      const companyBrands = brandsWithSamples.filter(b => b.companyId === companyId)

      setSelectedCompanyId(companyId)
      setSelectedBrandId(companyBrands.find(brand => brand.id === selectedBrand?.id)?.id)
    } else if (!brandId && !companyId && selectedBrand) {
      setSelectedBrand(undefined)
      setSelectedBrandId(undefined)
      setSelectedCompanyId(undefined)
    }
  }, [brandId, brandsWithSamples, companies, companyId, selectedBrand])

  const shouldNavigateBrand = !isAdmin && !agencyId && !brandId && !selectedBrand

  useEffect(() => {
    if (shouldNavigateBrand) {
      const primaryBrandId = user.primaryBrandId
      const primaryBrand = brandsWithSamples.find(b => b.id === primaryBrandId)

      //not brands with samples because we want to select th first brand that is not sample
      const initialBrandId = primaryBrand?.id || first(brands)?.id

      if (primaryBrand) {
        mixpanel.people.set_once({ primaryBrandId, primaryBrand: primaryBrand?.name })
      }

      if (initialBrandId) {
        navigate(generatePath(ROUTES.BRAND, { brandId: initialBrandId }))
      }
    }
  }, [brandsWithSamples, navigate, shouldNavigateBrand, user.primaryBrandId])

  useEffect(() => {
    if (selectedBrandId) {
      setLoadingResources(true)
      getBrand(selectedBrandId)
        .then(setSelectedBrand)
        .finally(() => setLoadingResources(false))
    }
  }, [selectedBrandId, setLoading])

  useEffect(() => {
    if (selectedCompanyId) {
      getCompany(selectedCompanyId).then(setSelectedCompany)
    }
  }, [selectedCompanyId])

  useEffect(() => {
    updateContext({
      userId: user.id,
      properties: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        ...(!isAdmin && {
          companies: companies.map(c => c.name).join(','),
          companyIds: companies.map(c => c.id).join(',')
        }),
        agencyId: agencyId || '',
        isAdmin: Boolean(isAdmin).toString()
      }
    })
  }, [user.id, companies])

  useEffect(() => {
    if (includeSamples) {
      enableInterceptedApi(true, user)
      const firstCompany = first(companies) || SAMPLE_COMPANY
      if (
        selectedBrand &&
        SAMPLE_COMPANY.brands.find(brand => brand.id === selectedBrand?.id) &&
        selectedBrand.companyId !== firstCompany.id
      ) {
        setSelectedBrand({
          ...selectedBrand,
          companyId: firstCompany.id
        })
      }
    } else if (!isAdmin) {
      enableInterceptedApi(false, user)
      setCompanies(prevCompanies => uniqBy(prevCompanies, 'id'))
      setBrands(prevBrands => uniqBy(prevBrands, 'id'))
    }
  }, [includeSamples, selectedBrand, agencyId])

  const onSelectBrand = async (brandId?: string) => {
    if (!brandId) {
      setSelectedBrandId(undefined)
      return
    }

    if (selectedBrand?.id === brandId) {
      return
    }

    const brand = brandsWithSamples.find(b => b.id === brandId)

    if (brand && !location.pathname.includes(brandId)) {
      navigate(generatePath(ROUTES.BRAND, { brandId }))
    }
  }

  const refreshCompany = async (companyId: string) => {
    const newCompany = await getCompany(companyId)

    if (newCompany) {
      if (!companiesWithSamples.find(c => c.id === companyId)) {
        setCompanies(prevCompanies => [newCompany, ...prevCompanies])
      } else {
        setCompanies(prevCompanies => prevCompanies.map(c => (c.id === newCompany.id ? newCompany : c)))
      }
      if (newCompany.id === selectedCompany?.id) {
        setSelectedCompany(newCompany)
      }
    }
  }

  const refreshBrand = async (brandId: string) => {
    const newBrand = await getBrand(brandId)

    if (newBrand) {
      if (!brandsWithSamples.find(b => b.id === brandId)) {
        setBrands(prevBrands => [newBrand, ...prevBrands])
      } else {
        setBrands(prevBrands => prevBrands.map(b => (b.id === newBrand.id ? newBrand : b)))
      }

      if (newBrand.id === selectedBrandId) {
        setSelectedBrand(newBrand)
      }
    }
  }

  const refreshAgency = async (agencyId: string) => {
    const newAgency = await getAgency(agencyId)

    if (newAgency) {
      if (!agencies.find(b => b.id === agencyId)) {
        setAgencies(preAgencies => [newAgency, ...preAgencies])
      } else {
        setAgencies(preAgencies => preAgencies.map(b => (b.id === newAgency.id ? newAgency : b)))
      }
      if (newAgency.id === agency?.id) {
        setAgency(newAgency)
      }
    }
  }

  const { filteredCompanies, filteredBrands } = useMemo(() => {
    const brandsWithCounts = brandsWithSamples.map(brand => ({
      ...brand,
      totalProducts: countsByBrandWithSamples?.[brand.id]?.totalProducts || 0,
      totalRecommendations: countsByBrandWithSamples?.[brand.id]?.totalRecommendations || 0
    }))

    if (agencyId) {
      const filteredCompanies = companiesWithSamples.filter(company => company.agencyId === agencyId)
      const companyIds = map(filteredCompanies, 'id')

      return {
        filteredCompanies,
        filteredBrands: brandsWithCounts.filter(brand => brand.companyId && companyIds.includes(brand.companyId))
      }
    } else if (!isAdminManagementView && selectedCompanyId) {
      return {
        filteredCompanies: companiesWithSamples.filter(c => c.id === selectedCompanyId),
        filteredBrands: brandsWithCounts.filter(b => b?.companyId === selectedCompanyId)
      }
    } else {
      return {
        filteredCompanies: companies,
        filteredBrands: brandsWithCounts
      }
    }
  }, [companies, companiesWithSamples, brands, agencyId, countsByBrand, selectedCompanyId, isAdminManagementView])

  useEffect(() => {
    if (isAdmin) {
      enableInterceptedApi(true, user)
    }
  }, [isAdmin, user])

  if (loading) {
    return <Loading />
  }

  const isLoadingResources = !!(
    loadingResources ||
    loading ||
    (selectedBrand && brandId && selectedBrand?.id !== brandId)
  )

  return (
    <UserSessionContext.Provider
      value={{
        authUser,
        profile: user,
        isAdmin,
        isAdminView,
        isAdminManagementView,
        isViewOnly,
        setIsAdminView,
        companies: filteredCompanies,
        brands: filteredBrands,
        loadingResources: isLoadingResources,
        refreshBrand,
        refreshCompany,
        refreshAgency,
        selectedCompany,
        selectedBrand,
        setSelectedBrand: onSelectBrand,
        agencies,
        agency,
        agencyId,
        setAgencyId: setUserAgencyId,
        countsByBrand
      }}
    >
      <SubscriptionProvider>
        <Outlet />
        <Support />
      </SubscriptionProvider>
    </UserSessionContext.Provider>
  )
}
const proposalSet = [
  <Route key="product-creators" path={ROUTE_NAMES_PROPOSAL.PRODUCT_CREATORS} element={<ProductCreators />} />,
  <Route key="timeline" path={ROUTE_NAMES_PROPOSAL.TIMELINE} element={<Timeline />} />,
  <Route key="review" path={ROUTE_NAMES_PROPOSAL.REVIEW} element={<Review />} />,
  <Route key="win" path={ROUTE_NAMES_PROPOSAL.WIN} element={<CreateWin />} />
]

const adminManagementRoute = (element: ReactElement, isAdminManagementView?: boolean) =>
  !isAdminManagementView ? <Navigate to={ROUTES.HOME} replace /> : element

const useRouteWithNavLoading = (element: ReactElement, loading: boolean, options?: { excludeAppBar?: boolean }) => {
  return useRouteWithNav(routeWithLoading(element, loading), options)
}

const useRouteWithNav = (element: ReactElement, options?: { excludeAppBar?: boolean }) => {
  const { isMobile } = useLayoutContext()

  if (options?.excludeAppBar) {
    return (
      <React.Fragment>
        <NavDrawer />
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            pl: `${isMobile ? 0 : drawerWidth}px`,
            height: '100%'
          }}
        >
          {element}
        </Container>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <NavDrawer />
      <AppBar />
      <Container
        disableGutters
        maxWidth={false}
        sx={{ flexGrow: 1, bgcolor: 'background.default', pt: 8, pl: `${isMobile ? 0 : drawerWidth}px` }}
      >
        <Container>{element}</Container>
      </Container>
    </React.Fragment>
  )
}
const routeWithAppBar = (element: ReactElement, props?: { containerProps?: ContainerProps }) => {
  return (
    <React.Fragment>
      <AppBar />
      <Container sx={{ flexGrow: 1, pt: 8, height: '100%' }} {...props?.containerProps}>
        {element}
      </Container>
    </React.Fragment>
  )
}

const routeWithLoading = (element: ReactElement, loading: boolean) => {
  if (loading) {
    return <Loading />
  }
  return element
}
