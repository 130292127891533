import { InvoicePaymentType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { StringSchema } from 'yup'

export const HUBSPOT_ACCOUNT_ID = '7854176'

const commonFormValidation = {
  brandId: yup.string().required('Required'),
  status: yup.string().required('Required')
}

export const creatorPricingValidation = yup
  .array()
  .of(
    yup.object({
      type: yup.string().required('Required'),
      price: yup.number().required('Required'),
      numCreators: yup.number().optional(),
      socialCreatorPricing: yup
        .array()
        .of(
          yup.object({
            type: yup.string().required('Required'),
            price: yup.number().required('Required'),
            numCreators: yup.number().required('Required')
          })
        )
        .test(
          'numCreators',
          'The number of creators does not match the total creators committed to the client.',
          function (socialCreatorPricing, ctx) {
            const { numCreators } = this.parent

            if (!socialCreatorPricing) return true

            const totalSocialCreators = socialCreatorPricing?.reduce((total, item) => total + item.numCreators, 0)

            if (totalSocialCreators !== numCreators) {
              return ctx.createError({
                message: 'The number of creators does not match the total creators committed to the client.'
              })
            } else {
              return true
            }
          }
        )
    })
  )
  .required('Required')
  .min(1)

export const SUBSCRIPTION_TERM_MONTHS = [1, 3, 4, 6, 9, 12]

export const extraFeesFormValidation = {
  managedServiceFeeEnabled: yup.boolean().required(),
  managedServiceFee: yup
    .number()
    .min(0, 'Managed service fee cannot be negative')
    .notRequired()
    .when('managedServiceFeeEnabled', {
      is: true,
      then: s => s.required('Managed service fee is required'),
      otherwise: s => s.notRequired()
    }),
  subscriptionFeeEnabled: yup.boolean().required(),
  subscriptionFee: yup
    .number()
    .min(0, 'Subscription fee is required')
    .notRequired()
    .when('subscriptionFeeEnabled', {
      is: true,
      then: s => s.required('Subscription fee is required'),
      otherwise: s => s.notRequired()
    }),
  subscriptionTerm: yup
    .number()
    .oneOf(SUBSCRIPTION_TERM_MONTHS)
    .notRequired()
    .when('subscriptionFeeEnabled', {
      is: true,
      then: s => s.required('Subscription term is required'),
      otherwise: s => s.notRequired()
    })
}
export const extraFeesFormValidationSchema = yup.object(extraFeesFormValidation)

const creatorProductsFormValidation = {
  ...commonFormValidation,
  goal: yup.string().required('Required'),
  productId: yup.string().required('Required'),
  exchangeRate: yup.number().required('Required'),
  ratingGoal: yup
    .number()
    .when('goal', {
      is: ProposalGoal.BOOST_RATING,
      then: s => s.required('Required'),
      otherwise: s => s.optional().nullable()
    })
    .max(4.8, 'Our average creator rating is 4.8 stars, please choose a rating of 4.8 or lower.'),
  estimatedAverageRating: yup.number().optional().nullable().max(5, 'Rating cannot be larger than 5.0'),
  estimatedUnitsSoldPerWeek: yup.number().optional().nullable(),
  estimatedUnitsSoldPerMonth: yup
    .number()
    .moreThan(0, 'You must enter your estimated units sold in order to proceed to the next step.'),
  customFeedbackResponses: yup
    .number()
    .when('goal', {
      is: ProposalGoal.MARKET_RESEARCH,
      then: s => s.required('Required'),
      otherwise: s => s.optional().nullable()
    })
    .moreThan(0, 'You must enter the desired number of responses.'),
  creatorPricing: creatorPricingValidation,
  ...extraFeesFormValidation
}
export const creatorProductsFormValidationSchema = yup.object(creatorProductsFormValidation)

export const MAX_CUSTOM_QUESTIONS = 2
export const MAX_CUSTOM_QUESTIONS_LENGTH = 120

const timelineFormValidation = {
  ...commonFormValidation,
  launchDate: yup.mixed<DateTime>().optional(),
  creatorPricing: creatorPricingValidation,
  searchTerms: yup.array<StringSchema>().required('Required'),
  customFeedbackQuestions: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required('Required'),
        text: yup
          .string()
          .required('Required')
          .max(MAX_CUSTOM_QUESTIONS_LENGTH, `Maximum length is ${MAX_CUSTOM_QUESTIONS_LENGTH} characters`),
        type: yup.string().required('Required'),
        validation: yup
          .array()
          .of(
            yup.object({
              type: yup.string().required('Required'),
              parameters: yup.string().optional().nullable()
            })
          )
          .required('Required'),
        creditsCost: yup.number().required('Required')
      })
    )
    .max(MAX_CUSTOM_QUESTIONS, `You can only have up to ${MAX_CUSTOM_QUESTIONS} custom feedback questions.`)
    .optional()
    .nullable()
    .notRequired()
}

export const timelineFormValidationSchema = yup.object(timelineFormValidation)

const reviewFormValidation = {
  ...commonFormValidation,
  paymentType: yup.string<InvoicePaymentType>().optional().nullable(),
  invoiceLink: yup.string().optional().nullable(),
  invoiceDueDate: yup.mixed<DateTime>().optional().nullable(),
  invoiceStatus: yup.string().optional().nullable(),
  invoicePaidDate: yup.mixed<DateTime>().optional().nullable(),
  paymentBillingContact: yup
    .object({
      name: yup.string().optional().nullable(),
      email: yup.string().email('Invalid email').optional().nullable()
    })
    .when('paymentType', {
      is: InvoicePaymentType.NEW_INVOICE,
      then: s =>
        s
          .test(
            'RequiredContact',
            'You must have a billing contact in order to schedule the campaign. Contact customer success to update this billing contact',
            v => !!v && !!v.name && !!v.email
          )
          .required('Required'),
      otherwise: s => s.optional().nullable()
    }),
  billingContacts: yup
    .array(
      yup.object({
        name: yup.string(),
        email: yup.string().email('Invalid email')
      })
    )
    .when('paymentType', {
      is: InvoicePaymentType.NEW_INVOICE,
      then: s =>
        s
          .test(
            'min valid',
            'You must enter at least one billing email and contact name in order to schedule the campaign.',
            v => !!v && v.some(r => r.email && r.name)
          )
          .required('Required')
    }),
  hubspotDealLink: yup
    .string()
    .trim()
    .notRequired()
    .url('Invalid URL')
    .matches(
      /*
      Matches the following URLs:
      https://app.hubspot.com/contacts/7854176/deal/{id}
      https://app.hubspot.com/contacts/7854176/record/0-3/{id}
      */
      new RegExp(`https:\/\/app\\.hubspot\\.com\\/contacts\\/${HUBSPOT_ACCOUNT_ID}\/(deal|record\\/0-3)\\/\\d+`),
      'Invalid Hubspot URL'
    )
}
export const reviewFormValidationSchema = yup.object(reviewFormValidation)

export const proposalFormValidation = yup.object({
  ...commonFormValidation,
  ...creatorProductsFormValidation,
  ...timelineFormValidation,
  ...reviewFormValidation
})
