import Loading from '@momentum/components/loading'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { ReviewsGraph } from '@momentum/routes/campaign/e-commerce/reviews/reviews-graph'
import { SelectedReviews } from '@momentum/routes/campaign/e-commerce/reviews/selected-reviews'
import { Paper, Stack } from '@mui/material'
import { max, min, orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState, useEffect } from 'react'
import { PeriodSelector } from './PeriodSelector'
import { Summary } from './Summary'
import { CollectingResults } from './collecting-results'
import { ReviewsTable } from './reviews-table'
import ReviewsContext, { ViewType, TopLevelViewType } from './reviewsContext'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { TopLevelToggle } from './TopLevelToggle'
import { STORE_TO_RETAILER, Retailer } from '@productwindtom/shared-momentum'
import { NotSupported } from './not-supported'

export const DAYS_DATA = 14
export const Reviews = () => {
  const [topLevelViewType, setTopLevelViewType] = useState(TopLevelViewType.MOMENTUM)
  const [viewType, setViewType] = useState(ViewType.COUNT)

  const { isAdminView } = useUserSessionContext()
  const { campaignDetails, reviewMetrics } = useCampaignContext()

  const { startDate, endDate, product } = campaignDetails

  const is3pReviewsVisible = STORE_TO_RETAILER[product.store] === Retailer.AMAZON

  const momentumReviewRecordsDates =
    reviewMetrics?.filter(r => r.momentumReviewCount > 0).map(m => DateTime.fromISO(m.fromDate)) || []

  const startDateForFilter = min([DateTime.now(), DateTime.fromISO(startDate)])!
  const minDate = min([startDateForFilter, min(momentumReviewRecordsDates)])!

  const endDateDateTime = min([max(momentumReviewRecordsDates), endDate ? DateTime.fromISO(endDate) : DateTime.now()])
  const maxDate = min([
    endDateDateTime,
    endDate ? DateTime.fromISO(endDate).plus({ days: DAYS_DATA }) : DateTime.now()
  ])!

  const [startDateFilter, setStartDateFilter] = useState<DateTime>()
  const [endDateFilter, setEndDateFilter] = useState<DateTime>()

  useEffect(() => {
    if (reviewMetrics && !startDateFilter && !endDateFilter) {
      setStartDateFilter(startDateForFilter)
      setEndDateFilter(endDateDateTime)
    }
  }, [reviewMetrics])

  const isSupported =
    !!campaignDetails.numUgcCreators || !!campaignDetails.numSocialCreators || !!campaignDetails.numPremiumUgcCreators

  if (!isSupported) {
    return <NotSupported />
  }

  if (!reviewMetrics || !startDateFilter || !endDateFilter) {
    return <Loading />
  }

  if (!reviewMetrics.length || !reviewMetrics.find(r => r.momentumReviewCount)) {
    return <CollectingResults />
  }

  const filteredReviewData = orderBy(
    reviewMetrics.filter(
      d =>
        (!startDateFilter || d.fromDate >= startDateFilter.toISODate()!) &&
        (!endDateFilter || d.toDate <= endDateFilter.plus({ days: 1 }).toISODate()!)
    ),
    'date'
  )

  return (
    <ReviewsContext.Provider
      value={{
        reviewsData: reviewMetrics,
        filteredReviewData: filteredReviewData,

        is3pReviewsVisible,
        minDate,
        maxDate,
        topLevelViewType,
        setTopLevelViewType,
        viewType,
        setViewType,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter
      }}
    >
      <Stack spacing={5}>
        {isAdminView && <TopLevelToggle />}
        <Paper sx={{ px: 4, py: 2 }}>
          <Summary />
        </Paper>
        <PeriodSelector />
        <ReviewsGraph />
        <ReviewsTable />
        <SelectedReviews />
      </Stack>
    </ReviewsContext.Provider>
  )
}
