import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { useReviewsContext } from '@momentum/routes/campaign/e-commerce/reviewsV2/reviewsContext'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import 'chart.js/auto'
import 'chartjs-adapter-luxon'
import { DateTime } from 'luxon'
import { Line } from 'react-chartjs-2'
import CampaignLaunchImage from '/images/campaign-launch-outlined.png'
import CampaignEndedImage from '/images/calendar-check-outlined.png'
import RocketIcon from '@momentum/components/icons/rocket'
import CalendarCheckOutlineIcon from '@momentum/components/icons/calendar-check-outline'
import { first, last } from 'lodash'
import { ArrowUpward } from '@mui/icons-material'

export const StarGraph = () => {
  const theme = useTheme()
  const {
    campaignDetails: { startDate, endDate }
  } = useCampaignContext()
  const { filteredReviewData } = useReviewsContext()

  const startDateDateTime = DateTime.fromISO(startDate)
  const startDateNoTime = startDateDateTime.toISODate()
  const endDateDateTime = endDate ? DateTime.fromISO(endDate) : undefined
  const endDateNoTime = endDateDateTime?.toISODate()

  const summaryEndingRating = last(filteredReviewData)?.rating || 0
  const summaryRatingChange = (last(filteredReviewData)?.rating || 0) - (first(filteredReviewData)?.rating || 0)

  const startRating = first(filteredReviewData)

  const totalRatings = filteredReviewData.map(r => r.rating)

  const labels = filteredReviewData.map(d => d.date)
  const startDateIndex = labels.findIndex(v => v === startDateNoTime)
  const endDateIndex = endDateNoTime && labels.findIndex(v => v === endDateNoTime)
  const totalDatasetIndex = 0

  const tickDefinition = labels.map((_, index) => (labels.length < 15 ? 1 : index % 4 === 0 ? 1 : 0))

  const rocketImage = new Image(36, 36)
  rocketImage.src = CampaignLaunchImage

  const completedImage = new Image(36, 36)
  completedImage.src = CampaignEndedImage

  const momentumDataset = [
    {
      borderColor: theme.palette.info.main,
      pointBorderColor: theme.palette.info.main,
      pointBackgroundColor: theme.palette.info.main,
      data: totalRatings,
      pointStyle: totalRatings.map((_, i) =>
        i === startDateIndex ? rocketImage : i === endDateIndex ? completedImage : undefined
      )
    }
  ]

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
        <Stack
          direction={'row'}
          bgcolor={'white'}
          p={2}
          border={t => `1px solid ${t.palette.grey.A200}`}
          borderRadius={'4px'}
          flexGrow={1}
        >
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box width={8} bgcolor={'info.main'} borderRadius={100} height={75} />
            <Stack>
              <Stack direction={'row'} spacing={1}>
                <Typography variant={'h3'}>{summaryEndingRating}</Typography>
                {summaryRatingChange > 0 && (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    color={`success.main`}
                    bgcolor={'rgba(208, 241, 225, 0.40)'}
                    spacing={0.5}
                    px={1}
                    py={0.5}
                    borderRadius={30}
                  >
                    <ArrowUpward fontSize={'mSmall'} />
                    <Typography variant={'label3'}>{summaryRatingChange.toFixed(2)}!</Typography>
                  </Stack>
                )}
              </Stack>
              <Typography variant={'label3'}>Star rating</Typography>
              <Typography variant={'label3'} color={'grey.A700'}>
                from {startRating?.rating?.toFixed(2)} star rating
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          bgcolor={'white'}
          p={3}
          border={t => `1px solid ${t.palette.grey.A200}`}
          borderRadius={'4px'}
          spacing={2}
        >
          <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
            <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
              <RocketIcon fontSize={'mSmall'} sx={{ color: t => t.palette.grey.A700 }} />
            </Stack>
            <Stack>
              <Typography variant={'label2'}>Campaign start</Typography>
              <Typography variant={'label3'} color={'grey.A700'}>
                {startDateDateTime.toLocaleString(DateTime.DATE_MED)}
              </Typography>
            </Stack>
          </Stack>
          {!!endDateDateTime && (
            <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
              <Stack p={0.5} bgcolor={t => t.palette.grey.A100} borderRadius={'50%'}>
                <CalendarCheckOutlineIcon fontSize={'mSmall'} sx={{ color: t => t.palette.grey.A700 }} />
              </Stack>
              <Stack>
                <Typography variant={'label2'}>Campaign end</Typography>
                <Typography variant={'label3'} color={'grey.A700'}>
                  {endDateDateTime.toLocaleString(DateTime.DATE_MED)}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack bgcolor={'white'} border={t => `1px solid ${t.palette.grey.A200}`} borderRadius={'4px'}>
        <Box p={3}>
          <Typography variant={'label1'}>Star rating</Typography>
        </Box>
        <Divider />
        <Box py={3}>
          <Line
            data={{
              labels: labels,
              datasets: momentumDataset.map(d => ({
                ...d,
                borderWidth: 8,
                cubicInterpolationMode: 'monotone'
              }))
            }}
            height={500}
            options={{
              borderColor: theme.palette.grey.A400,
              elements: {
                point: {
                  radius: value => {
                    return value.datasetIndex === totalDatasetIndex &&
                      (value.dataIndex === startDateIndex || value.dataIndex === endDateIndex)
                      ? 10
                      : 0
                  },
                  hitRadius: 10,
                  drawActiveElementsOnTop: true
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  ...defaultTooltipOptions,
                  callbacks: {
                    label: item => {
                      if (item.datasetIndex === totalDatasetIndex && item.dataIndex === startDateIndex) {
                        return ['Campaign launch!', startDateDateTime.toLocaleString(DateTime.DATE_SHORT)]
                      }
                      if (
                        endDateDateTime &&
                        item.datasetIndex === totalDatasetIndex &&
                        item.dataIndex === endDateIndex
                      ) {
                        return ['Campaign complete!', endDateDateTime.toLocaleString(DateTime.DATE_SHORT)]
                      }

                      return `${item.formattedValue} reviews & ratings`
                    },
                    title: () => '',
                    footer: () => ''
                  }
                }
              },
              scales: {
                y: {
                  display: true,
                  beginAtZero: false,
                  grid: {
                    drawOnChartArea: true,
                    drawTicks: false,
                    color: theme.palette.grey.A200,
                    lineWidth: 1
                  },
                  offset: true,
                  ticks: {
                    font: {
                      family: theme.typography.fontFamily as string,
                      weight: theme.typography.fontWeightRegular as number,
                      size: theme.typography.label1.fontSize as number
                    },
                    color: 'black',
                    padding: 16
                  },
                  border: {
                    width: 1,
                    color: theme.palette.grey.A200
                  }
                },
                x: {
                  type: 'time',
                  ticks: {
                    source: 'labels',
                    minRotation: 60,
                    padding: 16,
                    font: (ctx, options) => {
                      const date = DateTime.fromMillis(ctx.tick.value).toISODate()
                      return {
                        family: theme.typography.fontFamily as string,
                        weight: date === startDateNoTime ? 800 : (theme.typography.fontWeightRegular as number),
                        size: theme.typography.label1.fontSize as number
                      }
                    },
                    color: value => {
                      const date = DateTime.fromMillis(value.tick.value).toISODate()
                      return date === startDateNoTime ? theme.palette.primary.main : 'black'
                    }
                  },
                  time: {
                    unit: 'day',
                    tooltipFormat: 'LL/dd',
                    displayFormats: {
                      day: 'LL/dd'
                    }
                  },
                  grid: {
                    drawOnChartArea: true,
                    drawTicks: false,
                    color: theme.palette.grey.A200,
                    lineWidth: tickDefinition
                  },
                  border: {
                    width: 1,
                    color: theme.palette.grey.A200
                  }
                }
              }
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
