import { Selector, GraphQLTypes, InputType } from '@productwindtom/shared-ws-zeus-types'

export const instantJoinUserSelector = Selector('User')({
  fraudReviewStatus: true,
  declineToReviewCount: true,
  incompleteCampaignCount: true,
  completedLiftCampaignCount: true,
  completedLifestyleCampaignCount: true,
  completedSocialCampaignCount: true,
  participatedLifestyleCampaignCount: true,
  claimedReviewCount: true,
  attributedReviewCount: true,
  amznReviewerId: true,
  collisionSkuSet: true,
  subscriptionStatus: true,
  subscriptionEndsAt: true
})

export type InstantJoinUser = InputType<GraphQLTypes['User'], typeof instantJoinUserSelector>

export const instantJoinProductSelector = Selector('Product')({
  instantJoinSeatsAvailable: true,
  campaignStateMachine: true
})

export type InstantJoinProduct = InputType<GraphQLTypes['Product'], typeof instantJoinProductSelector>

export const instantJoinCampaignSelector = Selector('Campaign')({
  collisionSkuDensity: {
    density: true,
    skuId: true
  }
})
export type InstantJoinCampaign = InputType<GraphQLTypes['Campaign'], typeof instantJoinCampaignSelector>
